import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const MSCMicrobiology=()=>{
    const progHiglight=[
{listName:'Availability of scholarships covering up to 100% of expenses.'},
{listName:'Successful job placement across diverse fields like medical biotechnology, Agriculture Biotechnology, Environment Biotechnology, Industrial Biotechnology, Bioinformatics, Genomics, Vaccinology and Allied Life Sciences streams such as Biochemistry, Genetics, Microbiology etc. showcasing a variety of opportunities with leading global corporations.'},
{listName:'Employment opportunities in prestigious organizations across defence, research, government, healthcare, etc.'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness.'},
{listName:'Prospects for advanced studies or research in both domestic and international institutions'},
{listName:'Modern laboratories equipped with sophisticated instruments.'},
{listName:'Choice-based Credit System in the curriculum.'},
{listName:'Curriculum crafted by industry professionals and distinguished academics.'},
{listName:'Emphasis on project-based and experiential learning methodologies'},
{listName:'Access to e-learning platforms like MOOC, NPTEL, and Coursera'},
{listName:'Tailored training programs to prepare students for placement opportunities.'},
{listName:'Highly motivated faculties expert of the domain has vast research experience for experiential learning.'},
{listName:'Faculties have publications in SCI-E/WoS and Scopus indexed Journals of high impact factor.'},
{listName:'Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student startups.'},
{listName:'Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships'},


        
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','2 Years':'₹77000/- (Tuition Fee - ₹60000 + Other Charges- ₹17000/-)'},
        {duration:'2nd Year Onwards Fees','2 Years':'₹77000/- ( Tuition Fee – ₹60000 + Other Charges- ₹17000/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 96000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹130000/-'}
    ]
    const duration='2 years'
    const fee_1st= '77,000'
    const fee_2nd= '77,000'
    const eligibility='Graduation in any stream of Basic Sciences (Botany, Zoology, Chemistry) & Life Sciences (Genetics, Biochemistry, Biotechnology, Biomedical Sciences, Allied Biological Sciences, Agriculture Sciences, Horticulture, Forestry, and Modern Biological Sciences. Minimum 55% aggregate marks for general category and 45% aggregate marks in case of SC/ST category.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="M.Sc. Microbiology" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b> Microbiology is a “Biology of Microorganisms”</b>
<br/><br/>
The M.Sc. Microbiology program spans two years, offering an advanced curriculum that provides comprehensive insights into microorganisms, encompassing viruses, bacteria, and algae, along with their roles in waste management and fermentation. It is an ideal choice for individuals aspiring to pursue a career in research within the field of Microbiology.<br/><br/>
The course delves into the fundamental functions of various microbes, including viruses, bacteria, fungi, algae, and more. A broad spectrum of topics is covered, ranging from microbial physiology, genetics, immunology, virology, mycology, to microbial ecology. Students enrolled in the MSc Microbiology program gain proficiency in advanced laboratory skills and techniques, enabling them to conduct thorough research and experiments within the realm of Microbiology.

    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Graduation in any stream of Basic Sciences (Botany, Zoology, Chemistry) & Life Sciences (Genetics, Biochemistry, Biotechnology, Biomedical Sciences, Allied Biological Sciences, Agriculture Sciences, Horticulture, Forestry, and Modern Biological Sciences.
<br/>
Minimum 55% aggregate marks for general category and 45% aggregate marks in case of SC/ST category.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection and ranking of candidates will be determined by their Graduation final examination scores.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Choice-Based Credit System offers a flexible framework enabling the customization of course designs and content, granting students the autonomy to select their preferred courses. Credits are allocated based on teaching hours, intricately linked to the depth and structure of each course. During the revision of the M.Sc. Microbiology program syllabi, extensive deliberations occurred in numerous sessions involving faculty members, students, and esteemed alumni. Valuable insights were shared, supplemented by input from prospective employers in the corporate sector. Additionally, expert opinions from various Microbiology domains were solicited and carefully considered. The presented syllabi represent a culmination of collaborative efforts among departmental faculty, incorporating feedback from students, alumni, external experts, and industry stakeholders. These syllabi have undergone thorough scrutiny and approval by the Committee of Courses of the Department of Biotechnology and Microbiology, as well as by the School of Allied Health Sciences at MVN University, Palwal.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The fee structure aims to provide clarity, empowering you and your family to plan effectively for the entire four-year program. Here, you'll discover a thorough breakdown of tuition fees, outlining costs for both the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    School of Allied Health Sciences
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Enhance your study experience at our institution by choosing from our array of hostel accommodations designed for your comfort and convenience. We provide diverse options to cater to your preferences, from triple-seater rooms, available in both air-conditioned and non-air-conditioned variants, to inclusive meal plans. Below, you'll discover comprehensive details on our hostel fee structures tailored to each accommodation option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default MSCMicrobiology;