import { Grid, Typography } from "@mui/material";
import MainTitle from "../../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../../utils/utils";
import BannerCoursesDurationFee from "../../../muiCommonComponent/bannerCoursesDurationFee";
const CSEMTech=()=>{
    const progHiglight=[
        {listName:'Advanced Curriculum: Focus on latest trends and technologies in computer science, including artificial intelligence, machine learning, big data analytics, cloud computing, and cybersecurity.'},
        {listName:'Research-Driven Education: Emphasis on research methodologies and techniques, encouraging students to participate in significant research projects under the guidance of experienced faculty.'},
        {listName:'Industry Collaboration: Partnerships with leading tech companies to integrate real-world applications into the curriculum, offering students exposure to industry practices and challenges.'},
        {listName:'State-of-the-Art Facilities: Access to modern laboratories and computing facilities equipped with the latest technology and software tools for hands-on learning and experimentation.'},
        {listName:'Capstone Projects: Requirement to complete a capstone project that allows students to apply their knowledge to complex problems in a real-world setting, often in collaboration with industry partners.'},
        {listName:'Workshops and Seminars: Regularly organized workshops, seminars, and guest lectures by industry experts and academicians to enhance learning and keep students updated with industry trends.'},
        {listName:'Interdisciplinary Approach: Opportunities to work on interdisciplinary projects combining computer science with other fields such as biology, finance, and engineering.'},
        {listName:'Skill Development: Focus on developing professional skills such as project management, teamwork, and effective communication, essential for leadership roles in technology.'},
        {listName:'Global Opportunities: Opportunities for internships and research projects abroad, enhancing global exposure and understanding of international market demands.'},
        {listName:'Strong Alumni Network: Access to a vast network of alumni working in top positions in industry and academia, providing mentoring and job placement support.'}
        
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','2 Years':'₹77000/- (Tuition Fee - ₹60000 + Other Charges- ₹17000/-)'},
        {duration:'2nd Year Fees','2 Years':'₹77000/- ( Tuition Fee – ₹60000 + Other Charges- ₹17000/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 96000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹130000/-'}
    ]
    const duration='2 years'
    const fee_1st= '77000'
    const fee_2nd= '77000'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
</Grid>
<Grid item xs={12}>
    <MainTitle title="M. Tech Computer Science and Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Master of Technology (Full Time) in Computer Science and Engineering emphasizes practical design and hands-on experience. This program delves deeper into various engineering topics initially introduced in the core curriculum, focusing on both theoretical and programming solutions to real-world problems, as well as system design pertinent to software companies. Key subjects covered by the department include Mathematical Foundations for Data Science-1, Advance Networking, Next Generation Data Bases, among others. Beyond classroom and lab learning, students undertake a dissertation in their final year, which includes a full semester of industry experience. This program establishes a robust foundation in fundamental Computer principles, enhancing critical thinking and problem-solving abilities. Throughout their academic journey, students also acquire strong written and oral communication skills, learn teamwork dynamics, and gain project management experience.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Minimum 50% Marks or equivalent CGPA in B.Tech.- Electronics & Communication Engg. /Telecommunication Engg./Computer Science & Engg. /Information Technology/Electronics & Instrumentation Engg./Instrumentation & Control Engg. /Electrical &Electronics Engg./Electrical Engg./Power Electronics or MCA or MSc-Computer Science/Information Technology/Electron ics/Physics/Mathematics.

    </Typography>
    <br/>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    . Criteria for Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Merit preparation / short listing of candidates shall be on the basis of Valid GATE score/ Marks in Qualifying Examination.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The M.Tech in Computer Science and Engineering program at MVN University is designed to advance knowledge and skills in the field of computing. Here are some of the key highlights of the program:
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    These program highlights demonstrate the commitment of the M.Tech in Computer Science and Engineering at MVN University to provide a comprehensive and dynamic education that prepares graduates for successful careers in technology and research.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    M.Tech Computer Science & Engineering Program Fee Structure
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you'll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEMTech;