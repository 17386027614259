import { Grid, Typography } from "@mui/material";
import MainTitle from "../../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../../utils/utils";
import BannerCoursesDurationFee from "../../../muiCommonComponent/bannerCoursesDurationFee";
const CSEBCAAI=()=>{
    const progHiglight=[
        {listName:'Choice-Based Credit System with Core, Foundation, Domain and Open elective baskets.'},
        {listName:'Association with knowledge partners'},
        {listName:'Project Based Learning in mentorship of Faculty and Industry experts.'},
        {listName:'Dedicated Innovation'},
        {listName:'Latest curriculum as per Industry Standards in cutting edge specializations.'},
        {listName:'More than 80% faculty members are doctorate with strong industry and research background'},
        {listName:'The presence of a strong mentoring System and active involvement of faculty provides a conducive environment for holistic development of the students.'},
        {listName:'Student-led Workshops, Synergism, Alumni Talk, participation in national and international project competitions, guidance and funding support for StartUps.'},
        
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹82000/- (Tuition Fee - ₹65000 + Other Charges- ₹17000/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹82000/- ( Tuition Fee – ₹65000 + Other Charges- ₹17000/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 96000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹130000/-'}
    ]
    const duration='3 years'
    const fee_1st= '82,000'
    const fee_2nd= '82,000'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
</Grid>
<Grid item xs={12}>
    <MainTitle title="Bachelor of Computer Applications (Artificial Intelligence)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Bachelor of Computer Applications (Artificial Intelligence) program is a dynamic three-year undergraduate degree designed to equip students with a deep understanding of computer applications and information technology. The program features a comprehensive curriculum that covers essential subjects including programming languages, data structures, algorithms, database management, web development, software engineering, and networking. Students engage in hands-on learning through practical assignments, projects, and industry-relevant tasks, enabling them to develop real-world skills sought after by employers in the IT sector.  The BCA program also focuses on fostering critical thinking, problem-solving, and analytical skills, empowering students to analyze complex problems and develop effective solutions using computational methods and tools. Annually, "Code Begins" event is organized for BCA students with the aim of enhancing their coding skills. Challenging coding problems are tackled by participants to demonstrate their proficiency and compete for awards recognizing 1st, 2nd, and 3rd place. Through this event, innovation, teamwork, and excellence are fostered, preparing students for successful careers in the IT industry.
Additionally, students gain insights into project management methodologies, enabling them to plan, execute, and deliver IT projects efficiently. Through industry internships and practical training opportunities, students acquire valuable hands-on experience, preparing them for successful careers in the rapidly evolving field of information technology.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Bachelor of Computer Application (Artificial Intelligence) 
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you'll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEBCAAI;