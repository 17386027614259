import React from 'react';
import { Typography, Grid, Card, Avatar, Box } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import { TableDataPresentation, ListDataPresentation } from '../../utils/utils';
import ImagesSlider from '../muiCommonComponent/imageSlider';
import CourseGrid from '../muiCommonComponent/coursesButtonGrid';

const companyLogos = [
    {img:'/assets/img/SOPS/company1.png'},
    {img:'/assets/img/SOPS/company2.png'},
    {img:'/assets/img/SOPS/company3.png'},
    {img:'/assets/img/SOPS/company4.png'},
    {img:'/assets/img/SOPS/company5.jpg'},
    {img:'/assets/img/SOPS/company6.png'},
    {img:'/assets/img/SOPS/company7.png'},
    {img:'/assets/img/SOPS/company8.png'},
    {img:'/assets/img/SOPS/company9.jpg'},
    {img:'/assets/img/SOPS/company10.png'},
    // Add more logo URLs as needed
];
function Home() {
const courses = [
  {name:'Bachelor of Pharmacy (B.Pharm)',url:'/school-Of-pharmaceutical-sciences/b-pharma'},
  {name:'Diploma in Pharmacy (D.Pharm)',url:'/school-Of-pharmaceutical-sciences/d-pharma'},
  {name:'Master of Pharmacy (Pharmaceutics)',url:'/school-Of-pharmaceutical-sciences/m-pharma'},
  {name:'Master of Pharmacy (Pharmacology) *',url:'/school-Of-pharmaceutical-sciences/m-pharma'},
  {name:'Master of Pharmacy (Regulatory Affairs) *',url:'/school-Of-pharmaceutical-sciences/m-pharma'},
  {name:'Pharm D*',url:'#'}
]; 
  
  const offeredProgramme=[
    {'program':'B.A.LL.B.','duration':'5','Eligibility Criteria':'Senior Secondary or 10+2 or 12 th or equivalent from a recognised Board/ University with the minimum percentage as per BCI norms. Minimum 45% (GEN), 42% (OBC), and 40% (SC/ST).'},
    {'program':'B.B.A.LL.B','duration':'5','Eligibility Criteria':'Senior Secondary or 10+2 or 12 th or quivalent from a recognised Board/ University with the minimum percentage as per BCI norms.Minimum 45% (GEN), 42% (OBC), and 40% (SC/ST).'},
    {'program':'LL.B.','duration':'3','Eligibility Criteria':'Graduation or post-graduation from a recognised University with the minimum percentage as per BCI norms.Minimum 45% (GEN), 42% (OBC), and 40% (SC/ST).'},
    {'program':'LL.M.','duration':'1','Eligibility Criteria':'Law graduation from a recognised University with the minimum percentage as per UGC/ University norms.'},
]
const progHiglight=[
 {listName:'Expert guidance for various competitive exam such as Graduate Pharmacy Aptitude Test, Drug Inspector Test, D.Pharm. Exit Exam Test, Government Pharmacist Exam etc'},
 {listName:'Industry oriented project in Ph.D., M. Pharm., & B.Pharm.'},
 {listName:'MOUs with top notch Pharmaceutical Industries / Hospitals & NGOs.'},
  {listName:'Placement in Multinational Companies'},
  {listName:'Industrial training after third year.'},
  {listName:'GPAT qualified faculty members.'},
  {listName:'Focus on research publications in reputed journals with high impact factors.'},
  {listName:'Fosters a strong research culture among its faculty and students.'},
  {listName:'Aim to contribute to the advancement of knowledge and address real-world challenges.'}
  
]
const eventOrgnized=[
  {img:'/assets/img/SOPS/event1.jpg'},
  {img:'/assets/img/SOPS/event2.jpg'},
  {img:'/assets/img/SOPS/event3.jpg'},
  {img:'/assets/img/SOPS/event4.jpg'},
  {img:'/assets/img/SOPS/event5.jpg'},
  {img:'/assets/img/SOPS/event6.jpg'},
  {img:'/assets/img/SOPS/event7.jpg'},
  {img:'/assets/img/SOPS/event8.jpg'},
  {img:'/assets/img/SOPS/event9.jpg'},
  {img:'/assets/img/SOPS/event10.jpg'},
  {img:'/assets/img/SOPS/event11.jpg'},
  {img:'/assets/img/SOPS/event12.jpg'},
  {img:'/assets/img/SOPS/event13.jpg'},
]
const strengths=[
  {listName:'A team of experienced and qualified faculty members who bring both academic expertise and industry experience to the classroom, ensuring students receive a well-rounded education.'},
  {listName:'Strong ties up with pharmaceutical companies for internships, placements, and collaborative research projects, providing students with practical exposure and opportunities for real-world learning.'},
  {listName:'Modern laboratories equipped with advanced instrumentation and facilities, enabling students to conduct experiments and research in line with current industry standards.'},
  {listName:'Emphasis on research and innovation, encouraging students and faculty to engage in cutting-edge research projects that contribute to the advancement of pharmaceutical science.'},
  {listName:'Dedicated placement cell providing support to students for internships, placements, and career guidance, helping them secure job opportunities in reputed pharmaceutical companies.'},
  {listName:'Opportunities for students to participate in international conferences, seminars, and exchange programs, fostering global perspectives and networking opportunities within the pharmaceutical community.'},
  {listName:'Over 100 publications of our faculty at various national and international Journals indexed in SCI/ SCI-E/ Scopus/ PubMed and WoS'}
    
]
const knowledge=[
  {listName:'Multi Commodity Exchange of India Limited (MCX)'},
 {listName:'ICAI, Northern India Regional Council Faridabad Chapter.'},
]
const awardImage=[
  {img:'/assets/img/SOPS/award1.jpg'},
  {img:'/assets/img/SOPS/award2.jpg'},
  {img:'/assets/img/SOPS/award3.jpg'},
  {img:'/assets/img/SOPS/award4.jpg'},
]
const mediaImage=[
  {img:'/assets/img/SOPS/media1.jpg'},
  {img:'/assets/img/SOPS/media2.jpg'},
  {img:'/assets/img/SOPS/media3.jpg'},
  {img:'/assets/img/SOPS/media4.jpg'},
  {img:'/assets/img/SOPS/media5.jpg'},
  {img:'/assets/img/SOPS/media6.jpg'},
  {img:'/assets/img/SOPS/media7.jpg'},
  {img:'/assets/img/SOPS/media8.jpg'},
  {img:'/assets/img/SOPS/media9.jpg'},
  {img:'/assets/img/SOPS/media10.jpg'},
  {img:'/assets/img/SOPS/media11.jpg'},
  {img:'/assets/img/SOPS/media12.jpg'},
  {img:'/assets/img/SOPS/media13.jpg'},
  {img:'/assets/img/SOPS/media14.jpg'},
  {img:'/assets/img/SOPS/media15.jpg'},
  {img:'/assets/img/SOPS/media16.jpg'},
  {img:'/assets/img/SOPS/media17.jpg'},
  {img:'/assets/img/SOPS/media18.jpg'},
  {img:'/assets/img/SOPS/media19.jpg'},
  {img:'/assets/img/SOPS/media20.jpg'},
  {img:'/assets/img/SOPS/media21.jpg'},
 
]
const grandRecived=[
  {img:'/assets/img/SOPS/grant1.jpg'},
]
const studentArr=[
  {img:'/assets/img/SOPS/student1.jpg'},
  {img:'/assets/img/SOPS/student2.jpg'},
  {img:'/assets/img/SOPS/student3.jpg'},
  {img:'/assets/img/SOPS/student4.jpg'},
  {img:'/assets/img/SOPS/student5.jpg'},
  {img:'/assets/img/SOPS/student6.jpg'},
  {img:'/assets/img/SOPS/student7.jpg'},
]
const feeArr=[
  {'Courses':'B.Pharm.','duration':'4','Tution Fee':'1,25000','1 year fees':'1,50200','2nd Year Onwards':'1,34200'},
  {'Courses':'D.Pharm','duration':'2','Tution Fee':'85000','1 year fees':'110200','2nd Year Onwards':'94200'},
  {'Courses':'M.Pharm (Pharmaceutics)','duration':'2','Tution Fee':'105000','1 year fees':'130200','2nd Year Onwards':'114200'},
  {'Courses':'M.Pharm (Pharmacology)*','duration':'2','Tution Fee':'105000','1 year fees':'130200','2nd Year Onwards':'114200'},
  {'Courses':'M.Pharm (Regulatory Affairs)*','duration':'2','Tution Fee':'105000','1 year fees':'130200','2nd Year Onwards':'114200'},
  {'Courses':'Pharm.D*','duration':'6','Tution Fee':'175000','1 year fees':'200200','2nd Year Onwards':'184200'},
]
const hostalFeeData=[
  {item:'Triple Seater with Meal','fee':'₹ 88000/-'},
  {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
]
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Pharmaceutical Sciences" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="ABOUT SCHOOL OF PHARMACEUTICAL SCIENCES" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Pharmacy education in India plays a vital role in producing skilled professionals who contribute significantly to the healthcare system. With advancements in healthcare and evolving pharmaceutical industries, it becomes imperative to continuously enhance the pharmacy programme to meet the demands of the modern world. Pharmacy education encompasses various programs tailored to equip students with the necessary knowledge and skills to excel in the pharmaceutical industry.
        <br/>
        <br/>
        The School of Pharmaceutical Sciences (SOPS) started its first batch of B.Pharm. courses in 2015, D.Pharm. course in 2016 & M.Pharm. course in 2021 and has been successfully marching ahead since inception. It is one of the fastest-growing leading pharmacy institutions in North India, providing air-conditioned class rooms, a Wi-Fi-enabled campus, and more than 7000 books in the pharmacy library with special emphasis on practical exposure for the students. Facilities available include a Central Instrumentation facility lab, Industrial Pharmacy lab, Pharmacology labs, Pharmacognosy labs, and Pharmaceutical Chemistry labs fully loaded with the latest models of machinery and instruments, which is very useful from the research point of view. SOPS is one of the many successful ventures of MVN University, Palwal (Haryana), which has a reputation for pioneering in pursuit of academic excellence. SOPS, in pursuit of its vision, is providing outstanding educational experiences in pharmacy and practice in a supportive environment of scholarship, research, and integrity
<br/>
  <br/>
  <b color='red'>We are your home for the healthier life.</b>
</Typography>
</Grid>

<Grid item xs={12} sm={12} md={12}>
        <MainTitle title="DEAN’S MESSAGE" />
      </Grid>

<Grid item xs={12} sm={4} md={4}>
        <Card sx={{ height: 'auto', padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '10px' }}>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <Avatar alt={'NP'} src={'/assets/img/SOPS/ashutoshupadhayay.jpg'} sx={{ width: 150, height: 150, marginBottom: 2 }} />
            <Box sx={{ background: '#ED1E24', borderRadius: '10px', padding: 2 }}>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
              Dr. Ashutosh Upadhayay
              </Typography>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
              Dean of School of Pharmaceutical Sciences
              </Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Welcome to the School of Pharmaceutical Sciences (SOPS), MVN University, Palwal. As the Dean and Professor, I am delighted to invite you to join our esteemed institution, where we are dedicated to nurturing future leaders in the field of pharmaceutical sciences.
<br/><br/>
Our comprehensive curriculum, state-of-the-art laboratories, and experienced faculty ensure a robust educational experience. We focus on innovative research, hands-on learning, and industry collaborations to equip you with the skills necessary to excel in this dynamic field.
<br/><br/>
At MVN, we emphasize holistic development, encouraging critical thinking, ethical practices, and a commitment to lifelong learning. Your journey with us will be transformative, preparing you to meet global healthcare challenges with confidence and competence.
<br/><br/>
I look forward to seeing you thrive and contribute meaningfully to the world of pharmaceuticals.
  
        </Typography>
      </Grid>

 
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Pharmaceutical Sciences Courses" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <CourseGrid courses={courses}/>
      {/* <TableDataPresentation data={offeredProgramme}/> */}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="PROGRAM HIGHLIGHTS" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={progHiglight}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Events Organized" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={eventOrgnized} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="MEDIA COVERAGE" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={mediaImage} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="AWARD HIGHLIGHTS" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={awardImage} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="STRENGTHS OF SCHOOL OF PHARMACEUTICAL SCIENCES" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={strengths}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="GRANTS RECIEVED" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={grandRecived} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="KNOWLEDGE PARTNERS / COLLABORATION" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <MainTitle title="MoU with Sarvodaya Hospital, Faridabad" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        MVN University, Palwal, and Sarvodaya Hospital, Faridabad, have inked an MOU. Thrilled to embark on this collaborative journey towards excellence in pharmaceutical education and healthcare.  #MVNUniversity #SarvodayaHospital #Training #Partnership #HealthcareEducation
</Typography>
<ImagesSlider imgArr={[{img:'/assets/img/SOPS/knowledge1.jpg'},{img:'/assets/img/SOPS/knowledge2.jpg'}]} />
</Grid>
<Grid item xs={12} sm={12} lg={12} md={12}>
      <MainTitle title="MoU with CFC Pharma Cluster Pvt Ltd, Karnal" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        MVN University, Palwal, achieves another milestone with a successful #MOU with CFC Pharma Cluster Pvt Ltd, Karnal!  This collaboration opens doors for impactful research projects and enriching training opportunities for our dedicated faculty members and students.  #MVNUniversity #CFCPharmaCluster #ResearchCollaboration #AcademicExcellence
</Typography>
<ImagesSlider imgArr={[{img:'/assets/img/SOPS/knowledge3.jpg'},{img:'/assets/img/SOPS/knowledge4.jpg'}]} />
</Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Top Recruiters" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={companyLogos} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="STUDENT TESTIMONIALS" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={studentArr} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="FEE STRUCTURE – 2024-25" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <TableDataPresentation data={feeArr}/>
      </Grid>
      <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>
  );
}

export default Home;
