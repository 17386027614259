import React from 'react';
import { Typography, Grid, Card, Avatar, Box } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import { ListDataPresentation, ReactSlider, TableDataPresentation } from '../../utils/utils';


function SOETContent() {

  

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Engineering and Technology" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >Welcome to the School of Engineering and Technology (SoET) at MVN University — where innovation meets excellence. Here, we empower the next generation of engineers and technologists with a rigorous curriculum across undergraduate (UG) and postgraduate (PG) programs in Computer Science and Engineering, Mechanical Engineering, Electronics and Communication Engineering, and Civil Engineering. Our commitment extends to advancing academic research, offering Ph.D. programs in these disciplines. Join our vibrant community, equipped with state-of-the-art research facilities, to explore ground breaking programs and collaborate with industry leaders. Embark on a journey at SOET to pave your way to a future filled with possibilities, and achievements, and become the change-maker and visionary the world needs.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Dean’s Message" />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Card sx={{ height: 'auto', padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '10px' }}>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <Avatar alt={'NP'} src={'/assets/img/dean-image.jpg'} sx={{ width: 150, height: 180, marginBottom: 2 }} />
            <Box sx={{ background: '#ED1E24', borderRadius: '10px', padding: 2 }}>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
                Professor N P Singh
              </Typography>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
                Dean of School of Engineering & Technology
              </Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >Welcome to the School of Engineering and Technology, MVN University, Palwal. The SoET is a community of learners dedicated to addressing complex engineering challenges, from rapid technological changes to sustainability, economic instability, and environmental issues. I have the pleasure of leading this school, a place where inquisitiveness, compassion, and intellect combine to create innovations that make lives better. The school was started as one of the first schools at MVN University. SoET is committed to fostering engineering competencies to bolster national advancement and foster wealth creation through the cultivation of a proficient workforce in engineering and technology domains. SoET is looking forward to being recognized internationally as a center of excellence. Our high-impact research at the undergraduate and graduate levels aims to endow students with advanced skills and expertise that put them advantageously in the fiercely competitive marketplace. I am extremely proud of the students, professors, technicians, leaders, and thinkers who are part of the School of Engineering and Technology. I am privileged to be a member of a team dedicated not only to advancing technology but also to enhancing its quality, thereby positively influencing the community.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif">
        </Typography>
      </Grid>




    </Grid>
  );
}

export default SOETContent;
