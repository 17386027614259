import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
const BBABA=()=>{
    const progHiglight=[
{listName:'Enhancing Skill Set: A Bachelor\'s degree in Business Administration with a specialization in Business Analytics empowers students to optimize and fully utilize their entire range of skills. This training also facilitates the cultivation of exceptional communication and presentation aptitudes, alongside the acquisition of expertise in data analytics. Additionally, possessing business acumen and knowledge in a given sector increases the likelihood of obtaining desirable roles in organizations that hire in fields such as finance, HR, retail, sales, or other areas. '},
{listName:'Focused on innovation: The pedagogy that emphasizes developing business acumen in students aims to teach the crucial element necessary for success in the business realm, which is innovation. By adopting a forward-thinking mindset, individuals can generate lucrative business ventures and navigate a path to success.'},
{listName:'Enjoying Leadership Positions: Graduates will be sought after for their expertise and support as business analysts. Advising an entire corporation on how to enhance the organization, develop new products, or engage in collaborations is a significant responsibility. Business analysts will thrive if they like the recognition and admiration they receive. If their preference is to maintain anonymity, they may be more suitable for a career as a data analyst. '},
{listName:'A course for all: The BBA in Business Analytics is a program that is suitable for students who possess not only strong mathematical and statistical skills, but also demonstrate creativity, innovation, curiosity, and an ability to identify patterns and linkages. This curriculum is available to students with expertise in analyzing trends and effectively applying them. '},
{listName:'Multidisciplinary: With the evolution of educational trends, the significance of multidisciplinary or interdisciplinary education is growing. An integrative education enhances a candidate\'s market value. This curriculum is interdisciplinary as it combines the core principles of computer science with data analysis and business. Furthermore, it will progressively focus more on industry-specific applications. '},
]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹77000/- (Tuition Fee - ₹60000 + Other Charges- ₹17000/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹77000/- ( Tuition Fee – ₹60000 + Other Charges- ₹17000/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 96000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹130000/-'}
    ]
    const duration='3 years'
    const fee_1st= '77,000'
    const fee_2nd= '77,000'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as a compulsory subject along with 4 subjects with the highest score out of the remaining subjects.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="BBA (Business Analytics)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    MVN University's BBA Business Analytics program is a specialized program that aims to develop business analytics professionals for a variety of industries. Business Analytics is a combination of Data Analytics and Business Intelligence. It is the science of analyzing data to find out patterns that will help develop strategies. This program helps to attain the knowledge required to drive key business decisions. It will also help in developing the necessary skills to carry out analytical procedures and support an organization by figuring out ways to improve and optimize existing business processes with ease. we offer specialization in the areas of Finance, Marketing & Business Analytics, Pharma Management, Hospital Management, and Human Resources.  Apart from the development of their academic & technical skills, we also conduct training and workshops on personality development, teamwork, confidence building, soft skills, and leadership qualities.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as a compulsory subject along with 4 subjects with the highest score out of the remaining subjects. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    BBA (Digital Marketing)
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default BBABA;