import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const BPharma=()=>{
    const progHiglight=[
{listName:'16 World Class laboratories with full loaded latest Instruments Such as UV, HPLC, Brookfield Viscometer and many more'},
{listName:'Curriculum aligning with PCI and offering industry-relevant elective courses'},
{listName:'Curriculum constructed by various industrial experts and eminent academicians from PCI'},
{listName:'GPAT qualified Faculty Members'},
{listName:'Expert guidance for various competitive exams such as GPAT examination, Drug Inspector Test, D.Pharm Exit Exam Test  and other competitive exams. '},
{listName:'Industry Oriented Projects in Ph.D, M.Pharm & B.Pharm.'},
{listName:'Foster a strong research culture among its faculty and students'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness'},
{listName:'Publication of over 100 research papers in both national and international journals and conferences'},
{listName:'Publication of about 20 books published by national and international publishing house. '},
{listName:'Organising health camps and diseases awareness rally on regular basis in nearby villages or towns to impart practical exposure to the students and social welfare'},
{listName:'Celebration of various special days associated with health like world heart day, world diabetes day, world tuberculosis day, and more'},
{listName:'Support from government departments like DST to organise the programmes aiming at innovations in field of science and technology  '},
{listName:'Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student startups'},
{listName:'Organizing conferences, short-term training, workshops, and webinars, best out of waste contest, project contests, and industry internships. '},
{listName:'Emphasis on experimental based learning. '},
{listName:'Conduct hospital training and industrial training to impart practical exposure to the students '},
{listName:'Successful job placement across diverse fields like research & development, regulatory affairs, manufacturing, pharmaceutical marketing, hospital pharmacist, drug inspector and more.'},   
{listName:'Availability of scholarships covering up to 100% of expenses.'},
      
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','4 Years':'₹147000/- (Tuition Fee - ₹130000 + Other Charges- ₹17000/-)'},
        {duration:'2nd Year Onwards Fees','4 Years':'₹147000/- ( Tuition Fee – ₹130000 + Other Charges- ₹ 17000/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 96000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹130000/-'}
    ]
    const duration='4 years'
    const fee_1st= '1,47,000'
    const fee_2nd= '1,47,000'
    const eligibility='Candidate shall have passed 10+2 examination conducted by the respective state/central government authorities recognized as equivalent to 10+2 examination by the Association of Indian Universities (AIU) with English as one of the subjects and Physics, Chemistry, Mathematics (P.C.M) and or Biology (P.C.B / P.C.M.B.) as optional subjects individually. Any other qualification approved by the Pharmacy Council of India as equivalent to any of the above examinations. '
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="B.Pharm" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pharmacy education in India plays a vital role in producing skilled professionals who contribute significantly to the healthcare system. With advancements in healthcare and evolving pharmaceutical industries, it becomes imperative to continuously enhance the pharmacy programme to meet the demands of the modern world. Pharmacy education encompasses various programs tailored to equip students with the necessary knowledge and skills to excel in the pharmaceutical industry.
<br/><br/>
The B.Pharm program at the School of Pharmaceutical Sciences, MVN University, is designed to provide students with a comprehensive understanding of pharmaceutical sciences and prepare them for careers in the pharmaceutical industry, research, hospitals, and academia. Here are some key aspects of the program:
<br/>
<ol>
<li><b>Curriculum:</b> The curriculum covers a wide range of subjects including pharmaceutics, pharmaceutical chemistry, pharmacology, pharmacognosy, pharmaceutical analysis, and pharmaceutical technology, Jusrisprudance, drug discovery, regulatory affairs, drug development, and quality assurance.</li>
<li><b>Practical Training:</b> The program emphasizes hands-on practical training in Industries, hospitals and laboratories to develop skills in drug formulation, analysis, and testing. </li>
<li><b>Research Opportunities:</b> The school is offering opportunities for students to engage in research projects under the guidance of faculty members. This can provide valuable experience and enhance students' understanding of the latest developments in pharmaceutical sciences.</li>
<li><b>Industry Collaboration:</b> The school have collaborations with pharmaceutical companies, research institutions, hospitals and regulatory agencies, providing students with exposure to industry practices and networking opportunities.</li>
<li><b>Career Prospects:</b> Graduates of the B.Pharm program from the School of Pharmaceutical Sciences, MVN University, are well-equipped to pursue careers as pharmacists, formulation scientists, quality control/quality assurance professionals, regulatory affairs specialists, and researchers in the pharmaceutical industry. They may also choose to pursue higher education in pharmacy or related fields.</li>
</ol>
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Minimum qualification for admission In B.Pharm</b>
<br/>
<b>First year B. Pharm:</b> Candidate shall have passed 10+2 examination conducted by the respective state/central government authorities recognized as equivalent to 10+2 examination by the Association of Indian Universities (AIU) with English as one of the subjects and Physics, Chemistry, Mathematics (P.C.M) and or Biology (P.C.B / P.C.M.B.) as optional subjects individually. Any other qualification approved by the Pharmacy Council of India as equivalent to any of the above examinations. 
<br/>
<b>B.Pharm lateral entry (to third semester):</b> A pass in D. Pharm. course from an institution approved by the Pharmacy Council of India under section 12 of the Pharmacy Act.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B.Pharma
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default BPharma;