import { Grid, Typography } from "@mui/material";
import MainTitle from "../../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../../utils/utils";
import BannerCoursesDurationFee from "../../../muiCommonComponent/bannerCoursesDurationFee";
const CSEBTech=()=>{
    const progHiglight=[
        {listName:'Advanced laboratories with full equipment'},
        {listName:'Curriculum aligning with AICTE standards and offering industry-relevant elective courses'},
        {listName:'Curriculum crafted by industry professionals and distinguished academics'},
        {listName:'Partnerships providing skill-enhancement training to boost job readiness'},
        {listName:'Access to e-learning platforms like MOOC, NPTEL, Coursera, and IBM'},
        {listName:'Tailored training programs to prepare students for placement opportunities'},
        {listName:'Publication of over 100 research papers in both national and international journals and conferences'},
        {listName:'Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student startups'},
        {listName:'Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships'},
        {listName:'Emphasis on project-based and experiential learning methodologies'},
        {listName:'Flexible credit system enabling students to pursue interdisciplinary minors'},
        {listName:'Courses on "Design Thinking and Innovation", focusing on originality, startup viability, and the documentation of outcomes such as research papers, patents, products, startups, or copyrights'},
        {listName:'Successful job placement across diverse fields like technology, telecommunications, consulting, and more, showcasing a variety of opportunities with leading global corporations'},
        {listName:'Employment opportunities in prestigious organizations across defence, research, government, telecommunications, banking, healthcare, etc.'},
        {listName:'Prospects for advanced studies or research in both domestic and international institutions'},
        {listName:'Availability of scholarships covering up to 100% of expenses.'},
    ]
    const feeStructureData=[
        /*{duration:'1st Year Fees','4 Years':'₹130200/- (Tuition Fee - ₹105000 + Other Charges- ₹25200/-)'},*/
        {duration:'1st Year Fees','4 Years':'₹124000/- (Tuition Fee - ₹107000 + Other Charges- ₹17000/-)'},
        {duration:'2nd Year Onwards Fees','4 Years':'₹124000/- ( Tuition Fee – ₹107000 + Other Charges- ₹ 17000/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 96000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹ 130000/-'}
    ]
    const duration='4 years'
    const fee_1st= '1,24,000'
    const fee_2nd= '1,24,000'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
</Grid>
<Grid item xs={12}>
    <MainTitle title="B. Tech Computer Science and Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Bachelor of Technology (B. Tech) in Computer Science & Engineering emphasizes practical design and hands-on experience. This program delves deeper into various engineering topics initially introduced in the core curriculum, focusing on both theoretical and programming solutions to real-world problems, as well as system design pertinent to software companies. Key subjects covered by the department include Artificial Intelligence, Machine Learning, Data structure and Algorithms, Big Data, Cloud Computing, Blockchain Technology among others. Beyond classroom and lab learning, students undertake an advanced programming project in their final year, which includes a full semester of industry experience. This program establishes a robust foundation in fundamental Computer Engineering principles, enhancing critical thinking and problem-solving abilities. Throughout their academic journey, students also acquire strong written and oral communication skills, learn teamwork dynamics, and gain project management experience. Annually, "Code Ignites" event is organized for B. Tech students with the aim of enhancing their coding skills. Challenging coding problems are tackled by participants to demonstrate their proficiency and compete for awards recognizing 1st, 2nd, and 3rd place. Through this event, innovation, teamwork, and excellence are fostered, preparing students for successful careers in the IT industry.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 examination with at least 55% marks for B. Tech in CSE programmes in aggregate in 5 subjects and eligibility shall be determined on the basis of percentage of aggregate marks in English, Physics, Chemistry & Mathematics.
    </Typography>
    <br/>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    . Criteria for Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection and ranking of candidates will be determined by their performance in the JEE Mains 2024, SAT, Pearson tests, or their 12th-grade final examination scores
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B.Tech Computer Science & Engineering Program Fee Structure
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you'll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEBTech;