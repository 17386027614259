import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Container, Stack, Typography, Avatar } from '@mui/material'
import ChatbotScript from "./ChatbotScript";
import TrackingScript from "../Pages/admissionForm/TrackingScript";

function NewMain() {
    const leftSideLinks = [
        { title: 'Mvn Society', link: '/mvn-society' },
        { title: 'MVN University', link: '/mvn-university' },
        { title: 'OPD Clinic', link: '/opd-clinic' },
        { title: 'Legal Aid', link: '/legal-aid' },
        { title: 'Health Camps', link: '/health-campus' },
        { title: 'Agriculture Extension', link: '/agriculture-extension' },
        { title: 'Social Responsibility', link: '#' },
        { title: 'Green Initiatives', link: '/green-initiatives' },
    ]
    const centerLinks = [
        { title: 'Placement', link: '/placements' },
        { title: 'Admission Notices', link: '#' },
        { title: 'Alumni Connect', link: '/alumni-connect' },
        { title: 'MVN Times', link: '#' },
        { title: 'MVN Excellence Awards', link: '#' },
        { title: 'Careers', link: '#' },
    ]
    return (<>
        <Stack sx={{ background: '#000', padding: '30px 0px' }}>
            <Container className='footer_r' sx={{ marginTop: '20px' }}>
                <Grid container spacing={10}>
                    {/* <Grid item xs={12} sm={12} md={4} lg={4} spacing={10}>
                        <Typography variant='h6' fontWeight={800} textAlign="center" color={'white'} marginBottom={'30px'} borderBottom="1px outset #ED1E24">
                            About Us
                        </Typography>
                        <Typography textAlign="justify" color={'white'}>
                            MVN University is a prestigious educational institution committed to providing high-quality education and fostering holistic development in its students. Located in Haryana, India, MVN University offers a diverse range of undergraduate, postgraduate, and doctoral programs across various disciplines.
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            <Link to="https://www.facebook.com/MVNUniversity" target='_blank'>
                                <Avatar sx={{ background: '#ED1E24' }}>
                                    <i className="fab fa-facebook-f" />
                                </Avatar>
                            </Link>
                            <Link to="https:www.instagram.com/mvnuniversityofficial/?hl=en" target='_blank'>
                                <Avatar sx={{ background: '#ED1E24' }}>
                                    <i className="fab fa-instagram" />
                                </Avatar>
                            </Link>
                            <Link to="https:linkedin.com/in/mvn-university-093a36162" target='_blank'>
                                <Avatar sx={{ background: '#ED1E24' }}>
                                    <i class="fab fa-linkedin" aria-hidden="true" />
                                </Avatar>
                            </Link>
                            <Link to="https:www.youtube.com/@mvnuniversity7934" target='_blank'>
                                <Avatar sx={{ background: '#ED1E24' }}>
                                    <i className="fab fa-youtube" />
                                </Avatar>
                            </Link>
                        </Stack>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={4} lg={4} spacing={10}>
                        <Typography variant='h6' fontWeight={800} textAlign="center" color={'white'} marginBottom={'30px'} borderBottom="1px outset #ED1E24">
                            MVN Eco System
                        </Typography>

                        <Stack flexDirection="column" justifyContent="space-evenly" spacing={1}>
                            {leftSideLinks.map((item) => (
                                <Typography color="white" sx={{ ":hover": { color: 'red' } }}>
                                <Link to={item.link}>
                                <Typography color="white" sx={{ ":hover": { color: 'red' } }}> {item.title}</Typography>
                                </Link>
                                </Typography>
                            ))}


                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} spacing={10}>
                        <Typography variant='h6' fontWeight={800} textAlign="center" color={'white'} marginBottom={'30px'} borderBottom="1px outset #ED1E24">
                            Important Links
                        </Typography>

                        <Stack flexDirection="column" justifyContent="space-evenly" spacing={1}>
                            {centerLinks.map((item) => (
                                <Typography color="white" sx={{ ":hover": { color: 'red' } }}>
                                <Link to={item.link}>
                                <Typography color="white" sx={{ ":hover": { color: 'red' } }}> {item.title}</Typography>
                                </Link>
                                </Typography>
                            ))}
                            {/* <Link to="/chancellors-message">
                                <Typography color="white">About</Typography>
                            </Link>
                            <Link to="/vice-chancellor-message">
                                <Typography color="white">Vice Chancellor’s Message</Typography>
                            </Link>
                            <Link to="/iqac">
                                <Typography color="white">IQAC</Typography>
                            </Link>
                            <Link to="/crc">
                                <Typography color="white">Corporate Resource Center</Typography>
                            </Link>
                            <Link to="/career">
                                <Typography color="white">Careers</Typography>
                            </Link>
                            <Link to="/">
                                <Typography color="white">Club@mvnu</Typography>
                            </Link>
                            <Link to="https://admission.mvn.edu.in/wp-content/uploads/2023/06/Ombudsman.pdf">
                                <Typography color="white">Ombudsman</Typography>
                            </Link>
                            <Link to="/contact">
                                <Typography color="white">Contact Us</Typography>
                            </Link> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} spacing={10}>
                        <Typography variant='h6' fontWeight={800} textAlign="center" color={'white'} marginBottom={'30px'} borderBottom="1px outset #ED1E24">
                            Contact Us
                        </Typography>
                        <Stack flexDirection="column" justifyContent="space-evenly" spacing={2}>
                            <Stack flexDirection="row">
                                <Avatar sx={{ background: '#ED1E24' }}>
                                    <i className="icon fal fa-phone" />
                                </Avatar>
                                <Link to="tel:+918397979225"><Typography color="white" margin="10px 10px" textAlign="center">+91-8397979225</Typography></Link>
                            </Stack>

                            <Stack flexDirection="row">
                                <Avatar sx={{ background: '#ED1E24' }}>
                                    <i className="icon fal fa-envelope" />
                                </Avatar>
                                <Link to="mailto:admissions@mvn.edu.in"><Typography color="white" margin="10px 10px" textAlign="center">admissions@mvn.edu.in</Typography></Link>
                            </Stack>

                            <Stack flexDirection="row">
                                <Avatar sx={{ background: '#ED1E24' }}>
                                    <i className="icon fal fa-map-marker-check" />
                                </Avatar>
                                <Typography color="white" margin="10px 10px" > Delhi-Agra Highway, Aurangabad, Palwal, Haryana 121105</Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
        <Stack sx={{ background: '#fff', padding: '20px 0px' }}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Link className='footerlogo' to="/">
                            <img src="/assets/img/logo/mvn-university-logo.png" alt="img" height='48px' width='auto' sx={{ backgroundBlendMode: 'lighten' }} />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography textAlign={"right"} marginTop={1}> Copyright © MVN University 2024 . All rights reserved.</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Stack>
        <ChatbotScript />
        {/* <TrackingScript /> */}
    </>)
}

export default NewMain