import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

function Main() {

    const [Btnshow, setBtnshow] = useState(false);
    const [mobile, setmobile] = useState(false);
    const [Events, setEvent] = useState(false);
    const [News, setNews] = useState(false);
    const [Services, setServices] = useState(false);
    const [Blog, setblog] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const topMenus = [
        {
            mainHeader: 'NIRF',
            subHeader: [
                { title: 'MVN University,Palwal(2022-23)', path: ' https://admission.mvn.edu.in/wp-content/uploads/2023/02/MVN-University-Palwal.pdf' },
                { title: 'MVN University,Palwa — Pharmal(2022-23)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/02/MVN-University-Palwal-Pharmacy.pdf' },
                { title: 'MVN University,Palwal(2021-22)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/03/M-V-N-University-Palwal.pdf' },
                { title: 'MVN University,Palwal -- Pharma(2021-22)', path: ' https://admission.mvn.edu.in/wp-content/uploads/2022/03/M-V-N-University-Palwal-Pharmacy.pdf' },
                { title: 'MVN University,Palwal - Law', path: ' https://admission.mvn.edu.in/wp-content/uploads/2022/03/M-V-N-University-Palwal-Law.pdf' },
            ]
        },
        { mainHeader: 'NEP Scheme', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/05/FYUGP-BSC-CS-HONS-NEP-2020.pdf', subHeader: [] },
        {
            mainHeader: 'Ordinances', path: 'https://admission.mvn.edu.in/wp-content/uploads/2021/09/ordinances-and-regulations-of-undergraduate-programmes.pdf',
            subHeader: []
        },
        {
            mainHeader: 'CRC/Placement', subHeader: [
                { title: 'Corporate Resource Center', path: '/crc' },
                { title: 'Health Camp / Industrial Visits', path: '/' },
                { title: 'Industry Expert Talks', path: '/' },
                { title: 'Our Proud Alumni', path: '/' },
                { title: 'Our Recruiters', path: '/' },
                { title: 'Student Testimonials', path: '/' },
                { title: 'Placement at Glance', path: '/' },
            ]
        },
        {
            mainHeader: 'Research', subHeader: [
                { title: 'Doctorate Research', path: '/' },
                { title: 'Faculty Research', path: '/' },
            ]
        },
        {
            mainHeader: 'Mandatory Disclosure', subHeader: [
                { title: 'Info submitted to UGC', path: ' https://admission.mvn.edu.in/wp-content/uploads/2021/09/information-submitted-to-ugc.pdf' },
                { title: 'Student Grievance Committee', path: '/' },
                { title: 'Student Grievance Form', path: '/' },
                { title: 'Ombudsman ', path: ' https://admission.mvn.edu.in/wp-content/uploads/2023/06/Ombudsman.pdf' },
            ]
        }

    ]
    const headerMenus = [
        {
            mainHeader: 'About Us', subHeader: [{ title: 'Chancellor’s Message', path: '/chancellors-message', },
            { title: 'Vice Chancellor’s Message', path: '/vice-chancellor-message' },
            { title: 'Pro Vice Chancellor Message', path: '/pro-vice-chancellor-message' },
            { title: 'Registrar’s Message', path: '/Registrars_message' },
            { title: 'Why MVN', path: '/why-mvn' },  
            { title: 'Government recognitions', path: '/govermentReconigations' },
            { title: 'Infrastructure', path: '/infrastructure' },
            ], lastHeader: []

        },

        // { mainHeader: 'IQAC', path: '/iqac', subHeader: [] },
        {
            mainHeader: 'Admissions',
            subHeader: [
            { title: 'Admission policy', path: '/application-policy' },
            { title: 'Application Form', path: 'https://apply.mvn.edu.in/' },
            { title: 'Fee Structure', path: '/fee-structure' },
            { title: 'Scholarship', path: '/scholarship' },
            { title: 'Bank Account Details', path: '/bank-account-details' }]
        },        
        { mainHeader: 'Ombudsman ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/06/Ombudsman.pdf', subHeader: [] },
        {
            mainHeader: 'Committees', subHeader: [
                { title: 'Student Discipline Committee', path: '/StudentDisciplineCommittee' },
                { title: 'Student Grievance Form', path: '/student-grievance-form' },
                { title: 'University Students’ Grievances Redressal Committee', path: '/UniversityStudentsGrievancesRedressalCommittee' },
                { title: 'Internal Complaints Committee', path: '/InternalComplaintsCommittee' },
                { title: 'Women Cell', path: '/WomenCell' },
                { title: 'Games and Sports Committee', path: '/GamesandSportsCommittee' },
                { title: 'Anti-Ragging Committees', path: '/AntiRaggingCommittees' },
                { title: 'Cultural Committee', path: '/CulturalCommittee' },
                { title: 'Canteen Committee', path: '/CanteenCommittee' },
                { title: 'Purchase Committee', path: '/PurchaseCommittee' },
            ]
        },


        {
            mainHeader: 'Academics', subHeader: [
                { title: 'School of Engineering & Technology', path: '/computer-science-engineering' },
                { title: 'School of Law', path: '/school-of-law' },
                { title: 'School of Allied Health Sciences', path: '/school-of-allied-health-sciences' },
                { title: 'School of Pharmaceutical Sciences', path: '/school-of-pharmaceutical-sciences' },
                { title: 'School of Business Management & Commerce', path: '/school-of-business-management' },
                { title: 'School of Science & Humanities', path: '/school-of-arts-science-humanities' },
                { title: 'Faculty Accomplishments/Certifications', path: '/faculty-accomplishments-certifications' },
            ]
        },

        {
            mainHeader: 'Examination', subHeader: [
                { title: 'Examination Policy', path: '/examination-policy' },
                { title: 'Examination Notices', path: '/examnotice' },
                {title:'Branch of Examination Cell',path:'/branch-examination-cell'}
                // { title: 'Evaluation Scheme', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/05/Evaluation-Scheme.pdf' },
                // { title: 'Branch of Examination Cell ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/07/Branches-of-Examination-Cell.pdf' },
            ]
        },
        { mainHeader: 'Careers', path:'/career' , subHeader: [] },
        { mainHeader: 'Club@mvnu', path:'/' , subHeader: [] },
        { mainHeader: 'Mandatory Compliances', path:'/' , subHeader: [] },
        {
            mainHeader: 'Others', subHeader: [                
                {
                    title: 'CRC/Placement', lastHeader: [
                        { title: 'Corporate Resource Center', path: '/crc' },
                        { title: 'Health Camp / Industrial Visits', path: '/health-campus' },
                        { title: 'Industry Expert Talks', path: '/industry-export' },
                        // { title: 'Our Proud Alumni', path: '/' },
                        { title: 'Our Recruiters', path: '/our-recruiters' },
                        { title: 'Student Testimonials', path: '/students-testimonials' },
                        { title: 'Placement at Glance', path: '/placement-at-a-glance' },
                    ]
                },
                {
                    title: 'Mandatory Disclosure', lastHeader: [
                        { title: 'Info submitted to UGC', path: 'https://admission.mvn.edu.in/wp-content/uploads/2021/09/information-submitted-to-ugc.pdf' },
                        // { title: 'Student Grievance Committee', path: '/' },                     
                        
                        { title: 'Statutes ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Statutes.pdf' },
                        { title: 'Establishment & Act', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Establishment-Act.pdf' },
                        { title: 'Anti-Ragging Information', path: '/anti-ragging-information' },
                    ]
                },
                {
                    title: 'Research ', lastHeader: [
                        {
                            title: 'Doctorate Research', childHeader: [
                                // { title: 'Ph.D Program', path: '/doctoral-programs' },
                                // { title: 'Ph.D. Admissions 2023-24', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Ph.D.-2023-24-SUMMER-ADVERT.pdf' },
                                { title: 'Ph.D Guidelines', path: '/ph-d-guidelines' },
                                // { title: 'Ph.D Admission Form 2023-24 (SUMMER)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Ph.D-Admission-Form-2023-24-SUMMER.pdf' },
                                { title: 'Ph.D. Awarded till March 2023', path: '/ph-d-awarded' },
                                { title: 'List of Ph.D Research Scholars', path: '/list-of-ph-d-research-scholars' }
                            ]
                        },
                        
                        { title: 'Faculty Research', path: '/faculty-research' },
                        { title: 'R & D Cell', path: '/r-d-cell' },
                        { title: 'Ph.D Admission Form', path: '/phd-admission-form' },
                    ]
                },
                { title: 'IQAC', path: '/iqac' },
                { title: 'CUET', path: '/cuet-scholarship' },
                { title: 'NEP Scheme ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/05/FYUGP-BSC-CS-HONS-NEP-2020.pdf' },
                { title: 'Ordinances ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2021/09/ordinances-and-regulations-of-undergraduate-programmes.pdf' },
                {
                    title: 'NIRF', lastHeader: [
                        { title: 'MVN University,Palwal(2022-23)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/02/MVN-University-Palwal.pdf' },
                        { title: 'MVN University,Palwa — Pharmal(2022-23)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/02/MVN-University-Palwal-Pharmacy.pdf' },
                        { title: 'MVN University,Palwal(2021-22)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/03/M-V-N-University-Palwal.pdf' },
                        { title: 'MVN University,Palwal -- Pharma(2021-22)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/03/M-V-N-University-Palwal-Pharmacy.pdf' },
                        { title: 'MVN University,Palwal - Law', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/03/M-V-N-University-Palwal-Law.pdf' },
                    ]
                },
            ]
        },
        {
            mainHeader: 'Contact Us', path: '/contact', subHeader: []
        }

    ]

    return (
        <>
            <header className="header-area header-three">
                <div className="header-top second-header d-none d-md-block">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-4 d-none d-lg-block ">
                                <div className="header-social" style={{ margin: '10px' }}>
                                    <span>
                                        Follow us:-
                                        <Link target='_blank' to="https://www.facebook.com/MVNUniversity" title="Facebook">
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link target='_blank' to="https://www.instagram.com/mvnuniversityofficial/?hl=en" title="LinkedIn">
                                            <i className="fab fa-instagram" />
                                        </Link>
                                        <Link target='_blank' to="#" title="Twitter">
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link target='_blank' to="https://www.youtube.com/@mvnuniversity7934" title="Twitter">
                                            <i className="fab fa-youtube" />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 d-none d-lg-block text-right">
                                <div className="header-cta">
                                    <ul>
                                        <li>
                                            <div className="call-box">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/phone-call.png" alt="img" height={'36px'} width={'36px'} />
                                                </div>
                                                <div className="text">
                                                    <span>Call Now !</span>
                                                    <strong>
                                                        <Link to="tel:+917052101786">+91-8397979225</Link>
                                                    </strong>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="call-box">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/mailing.png" alt="img" height={'36px'} width={'36px'} />
                                                </div>
                                                <div className="text">
                                                    <span>Email Now</span>
                                                    <strong>
                                                        <Link to="mailto:info@example.com"> admissions@mvn.edu.in</Link>
                                                    </strong>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="top-second-menu">
                                    <div className="row align-items-center">                                    
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="top-menu text-left text-xl-left">
                                                <nav id='mobile-menu'>
                                                    <ul>
                                                        {topMenus?.map((menus) => {
                                                            return (
                                                                <li className="has-sub">
                                                                    <Link to={menus?.path}>{menus?.mainHeader} <i className={menus?.subHeader?.length > 0 && "fa fa-angle-down"}></i> </Link>
                                                                    <ul style={{ display: menus?.subHeader?.length == 0 && 'none' }}>
                                                                        {menus?.subHeader.map((subhead) => {
                                                                            return (
                                                                                <li>
                                                                                    <Link to={subhead?.path}>{subhead?.title}</Link>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>                                    
                                                    </div>  */}                           

                            </div>
                        </div>
                    </div>
                </div>
                <div id="header-sticky" className={`menu-area${isScrolled ? 'menu-area sticky-menu' : ''}`}>
                    <div className="container-fluid">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className='header-flex'>
                                        <div className="logo">
                                            <Link to="/">
                                                <img src="/assets/img/logo/mvn-university-logo.png" alt="logo" height='48px' width='auto' />
                                            </Link>
                                        </div>
                                        <div className="main-menu text-right">
                                            <nav id='mobile-menu'>
                                                <ul>
                                                    {headerMenus?.map((menus) => {
                                                        return (
                                                            <li className="has-sub">
                                                                <Link to={menus?.path}>{menus?.mainHeader} 
                                                                    {/* <i className={menus?.subHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                </Link>
                                                                <ul style={{ display: menus?.subHeader?.length == 0 && 'none' }}>
                                                                    {menus?.subHeader.map((subhead) => {
                                                                        return (
                                                                            <li className="has-sub">
                                                                                <Link to={subhead?.path}>{subhead?.title}  
                                                                                    {/* <i className={subhead?.lastHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                                </Link>
                                                                                <ul style={{ display: !subhead?.lastHeader && 'none' }}>
                                                                                    {subhead?.lastHeader?.map((lasthead) => {
                                                                                        return (
                                                                                            <li className="has-sub" >
                                                                                                <Link to={lasthead?.path}>{lasthead?.title} 
                                                                                                    {/* <i className={lasthead?.childHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                                                </Link>
                                                                                                <ul style={{ display: !lasthead?.childHeader && 'none',overflow: 'auto' }}>
                                                                                                    {lasthead?.childHeader?.map((childhead) => {
                                                                                                        return (
                                                                                                            <li>
                                                                                                                <Link to={childhead?.path}>{childhead?.title}</Link>
                                                                                                            </li>
                                                                                                        )
                                                                                                    })}
                                                                                                </ul>
                                                                                            </li>
                                                                                        )
                                                                                    })}
                                                                                </ul>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="login">
                                            <ul>
                                                <li>
                                                    <div className="second-header-btn">
                                                        <Link to="https://apply.mvn.edu.in/" className="btn"> admission open </Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mobile-menu mean-container">
                                        <div className="mean-bar" >
                                            <a href="#nav" onClick={() => setmobile(!mobile)} className={`meanmenu-reveal ${mobile && "meanclose"}`} style={{ right: 0, left: "auto", textAlign: "center", textIndent: 0, fontSize: 18 }} >
                                                {mobile ? "X" : <span><span><span></span></span></span>}
                                            </a>
                                            {mobile &&
                                                <nav className="mean-nav" style={{height: '600px',overflow: 'auto'}}>
                                                    <ul >
                                                        {headerMenus?.map((menus) => {
                                                            return (
                                                                <li className="has-sub">
                                                                    <Link to={menus?.path}>{menus?.mainHeader} 
                                                                        {/* <i className={menus?.subHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                      </Link>
                                                                    <ul style={{ display: menus?.subHeader?.length == 0 && 'none' }}>
                                                                        {/* {menus?.subHeader.map((subhead) => {
                                                                            return (
                                                                                <li>
                                                                                    <Link to={childhead?.path}>{subhead?.title}</Link>
                                                                                </li>
                                                                            )
                                                                        })} */}
                                                                        {menus?.subHeader.map((subhead) => {
                                                                            return (
                                                                                <li className="has-sub" >
                                                                                    <Link to={subhead?.path}>{subhead?.title}  
                                                                                        {/* <i className={subhead?.lastHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                                    </Link>
                                                                                    <ul style={{ display: !subhead?.lastHeader && 'none' }}>
                                                                                        {subhead?.lastHeader?.map((lasthead) => {
                                                                                            return (
                                                                                                <li className="has-sub">
                                                                                                    <Link to={lasthead?.path}>{lasthead?.title} 
                                                                                                        {/* <i className={lasthead?.childHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                                                    </Link>
                                                                                                    <ul style={{ display: !lasthead?.childHeader && 'none' }}>
                                                                                                        {lasthead?.childHeader?.map((childhead) => {
                                                                                                            return (
                                                                                                                <li>
                                                                                                                    <Link to={childhead?.path}>{childhead?.title}</Link>
                                                                                                                </li>
                                                                                                            )
                                                                                                        })}
                                                                                                    </ul>
                                                                                                </li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </nav>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {Btnshow &&
                <>
                    <div className={`offcanvas-menu ${"active"}`}>
                        <span className="menu-close" onClick={() => setBtnshow(false)}>
                            <i className="fas fa-times" onClick={() => setBtnshow(false)} />
                        </span>
                        <form role="search" method="get" id="searchform" className="searchform" action="#" >
                            <input type="text" name="s" id="search" placeholder="Search" />
                            <button>
                                <i className="fa fa-search" />
                            </button>
                        </form>
                        {/* <div id="cssmenu3" className="menu-one-page-menu-container">
                            <ul className="menu">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/courses">Courses</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/pricing">Pricing </Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/team">Teacher</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/projects">Gallery</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/blog">Blog</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                        <div id="cssmenu2" className="menu-one-page-menu-container">
                            <ul id="menu-one-page-menu-12" className="menu">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#home">
                                        <span>+8 12 3456897</span>
                                    </Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#howitwork">
                                        <span>info@example.com</span>
                                    </Link>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    <div className={`offcanvas-overly ${"active"}`}> </div>
                </>
            }
        </>
    )
}

export default Main