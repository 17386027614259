import { Grid, Typography } from "@mui/material";
import MainTitle from "../../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../../utils/utils";
import BannerCoursesDurationFee from "../../../muiCommonComponent/bannerCoursesDurationFee";
const ECEBTech=()=>{
    const progHiglight=[
{listName:'Advanced laboratories with state of art equipment'},
{listName:'Curriculum aligning with AICTE standards and offering industry-relevant elective courses'},
{listName:'Curriculum crafted by industry professionals and distinguished academics'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness'},
{listName:'Access to e-learning platforms like MOOC, NPTEL, Coursera, and IBM'},
{listName:'Tailored training programs to prepare students for placement opportunities'},
{listName:'Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student startups'},
{listName:'Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships'},
{listName:'Emphasis on project-based and experiential learning methodologies'},
{listName:'Flexible credit system enabling students to pursue interdisciplinary minors'},
{listName:'Courses on "Design Thinking and Innovation", focusing on originality, startup viability, and the documentation of outcomes such as research papers, patents, products, startups, or copyrights'},
{listName:'Successful job placement across diverse fields like technology, telecommunications, consulting, and more, showcasing a variety of opportunities with leading global corporations'},
{listName:'Employment opportunities in prestigious organizations across defence, research, government, telecommunications, banking, healthcare, etc.'},
{listName:'Prospects for advanced studies or research in both domestic and international institutions'},
{listName:'Availability of scholarships covering up to 100% of expenses.'},

    ]
    const feeStructureData=[
        {duration:'1st Year Fees','4 Years':'₹100200/- (Tuition Fee - ₹70000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','4 Years':'₹84200/- ( Tuition Fee – ₹75000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='4 years'
    const fee_1st= '100,200'
    const fee_2nd= '84,200'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
</Grid>
<Grid item xs={12}>
    <MainTitle title="B. Tech Electronics & Communication Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Bachelor of Technology (B. Tech) in Electronics and Communication Engineering emphasizes on practical approach and hands-on experience. The department encompasses researching, designing, developing, and testing electronic equipment utilized across diverse systems. India has a rapidly growing consumer market for electronic products, driven by rising incomes, increasing urbanization, and growing adoption of digital technologies.
    </Typography>
    </Grid>

    <Grid item xs={12} sm={12} md={12} className="ps-lg-5 ps-2">
      <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
         <ol>
          <li> <b>Growth Prospect:</b>
          According to the India Brand Equity Foundation (IBEF), the electronics manufacturing industry in India was valued at $75 billion in 2019-20 and is expected to reach $400 billion by 2025.</li>
          <li> <b>Make in India:</b>
          The government’s ‘Make in India’ initiative has attracted significant foreign direct investment (FDI) in the electronics manufacturing industry, with FDI in the electronics sector reaching USD 3.6 billion in 2022.</li>
          <li> <b>IT Industry: </b>
          India also has a large and an ever-growing pool of skilled labor, including engineers and technicians, which is essential for electronics manufacturing. The country’s strong IT industry can support the development of new technologies and the manufacturing of electronic products.</li>
          <li> <b>Industrial Parks and Special Economic Zones (SEZs): </b>
          The government has been investing in infrastructure development, including the development of industrial parks and special economic zones (SEZs) for electronics manufacturing. This provides companies with access to the necessary infrastructure and facilities for setting up, running, and expanding manufacturing operations in India.</li>
         </ol>
        </Typography>
      </Grid>

      <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    These data points suggest that India has a strong potential for electronics manufacturing, driven by a growing domestic market, government support, and a skilled labor force.
    <br/><br/>
 This program delves deeper into various engineering topics initially introduced in the core curriculum, focusing on both theoretical and practical solutions to real-world problems, to make our students in pace with real world. This program establishes a robust foundation in fundamental Electronics and Communication Engineering principles, enhancing critical thinking and problem-solving abilities. Throughout their academic journey, students also acquire strong written and oral communication skills, learn teamwork dynamics, and gain project management experience. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 examination with at least 55% marks for B. Tech in ECE programmes in aggregate in 5 subjects and eligibility shall be determined on the basis of percentage of aggregate marks in English, Physics, Chemistry & Mathematics.
    </Typography>
    <br/>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    . Criteria for Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection and ranking of candidates will be determined by their performance in the JEE Mains 2024 or Equivalent Entrance Exam  their 12th-grade final examination scores
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B.Tech Computer Science & Engineering Program Fee Structure
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you'll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default ECEBTech;