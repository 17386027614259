import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button,  Stack, Grid, Container } from '@mui/material';

function MenuHeaderTop() {
return(
    <Grid container spacing={2} marginTop={1} marginBottom={2}>
    <Container className='r_header'>
        <Stack flexDirection="row" justifyContent="space-between" className='reader_r'>
            <div className='h_t_center'>
                <Link to="/">
                    <img src="/assets/img/logo/mvn-university-logo.png" alt="logo" height="60px" />
                    <span className='established_text'>Established by Govt. of Haryana under <br/>  section 2(f) of UGC Act 1956, and approved by UGC</span>
                </Link>
            </div>
            <Stack flexDirection="column" justifyContent="space-between" spacing={1}>
            <Link to="/admission-form" ><Button variant='contained' color='error'>Admission Open</Button></Link>
                <Button variant='contained' color='error'>Email Now</Button>
                <Button variant='contained'color='error'>Jobs At MVN</Button>
                </Stack>
        </Stack>
    </Container>
</Grid>   
)
}
export default MenuHeaderTop;