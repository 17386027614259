import { Grid, Typography } from "@mui/material";
import MainTitle from "../../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../../utils/utils";
import BannerCoursesDurationFee from "../../../muiCommonComponent/bannerCoursesDurationFee";
const CIVILBTech=()=>{
    const progHiglight=[
{listName:'State of arts laboratories with full equipment'},
{listName:'Flexible choice-based credit system (CBCS) enabling students to pursue interdisciplinary minors'},
{listName:'Course curriculum designed with the input of Industry persons'},
{listName:'Outstanding placements records'},
{listName:'Project based teaching and learning'},
{listName:'Curriculum aligning with AICTE standards and offering industry-relevant elective courses'},
{listName:'Curriculum crafted by industry professionals and distinguished academics'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness'},
{listName:'Access to e-learning platforms like MOOC, NPTEL, Coursera etc.'},
{listName:'Tailored training programs to prepare students for placement opportunities'},
{listName:'Publication of over 100 research papers in both national and international journals and conferences'},
{listName:'Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student startups'},
{listName:'Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships'},
{listName:'Courses on "Design Thinking and Innovation", focusing on originality, startup viability, and the documentation of outcomes such as research papers, patents, products, start-ups, or copyrights'},
{listName:'Employment opportunities in various sectors like Automobile, Chemical, Aerospace and Defence, construction as well as Electronics industry. So its scope is equally in Government sector as well as in private sectors. '},
{listName:'Prospects for advanced studies or research in both domestic and international institutions'},
{listName:'Availability of scholarships covering up to 100% of expenses.'},

    ]
    const feeStructureData=[
        {duration:'1st Year Fees','4 Years':'₹87,000/- (Tuition Fee - ₹70,000 + Other Charges- ₹17,000/-)'},
        {duration:'2nd Year Onwards Fees','4 Years':'₹87,000/- ( Tuition Fee – ₹70,000 + Other Charges- ₹17,000/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 96,000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹ 1,30,000/-'}
    ]
    const duration='4 years'
    const fee_1st= '87,000'
    const fee_2nd= '87,000'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
</Grid>
<Grid item xs={12}>
    <MainTitle title="B. Tech Civil Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Civil engineering is a field dedicated to designing, constructing, and maintaining infrastructure essential for modern society. Civil engineers play a crucial role in shaping the built environment, from roads, bridges, and buildings to water supply systems, dams, and airports. They apply principles of mathematics, physics, and materials science to solve complex problems and ensure the safety, sustainability, and functionality of infrastructure projects. Civil engineering encompasses various specialized areas, including structural engineering, transportation engineering, environmental engineering, geotechnical engineering, and water resources engineering. As stewards of public safety and welfare, civil engineers work collaboratively with architects, urban planners, and policymakers to create resilient and sustainable solutions that meet the needs of communities while considering factors such as economic feasibility, environmental impact, and social equity.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 examination with at least 55% marks for B. Tech in CSE programmes in aggregate in 5 subjects and eligibility shall be determined on the basis of percentage of aggregate marks in English, Physics, Chemistry & Mathematics.
    </Typography>
    <br/>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    . Criteria for Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection and ranking of candidates will be determined by their performance in the JEE Mains 2024, SAT, Pearson tests, or their 12th-grade final examination scores
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B.Tech Computer Science & Engineering Program Fee Structure
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you'll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default CIVILBTech;