import React, { useEffect, useRef } from 'react';
import { Grid, Typography, Link } from "@mui/material";

const Notifications=()=>{

    const tickerRef = useRef(null);


    const notificationList=[
       /* {title:'Agriculture Theory 1st Sem. Datesheet ETE December-2024',url:'/assets/img/new_images/examination_datesheet/Agriculture Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'AHS Dept. Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/AHS Dept. Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'BA(Pol.)Sc. Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/BA(Pol.)Sc. Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'Engg. & CA Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/Engg. & CA Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'Law Deptt. Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/Law Deptt. Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:'Pharmacy Theory 1st Sem. Datesheet ETE December-2024  ',url:'/assets/img/new_images/examination_datesheet/Pharmacy Theory 1st Sem. Datesheet ETE December-2024.pdf'},
        {title:' SBMC Deptt. Theory 1st Sem. Datesheet ETE December-2024 ',url:'/assets/img/new_images/examination_datesheet/SBMC Deptt. Theory 1st Sem. Datesheet ETE December-2024.pdf'},

        */
        
    ]
 

    useEffect(() => {
        const ticker = tickerRef.current;
        const clone = ticker.cloneNode(true);
        ticker.parentNode.appendChild(clone);

        return () => {
        clone && clone.remove();
        };
    }, []);

    return(
        /*
        <Grid container spacing={2}>
            {notificationList.map((notiy)=>(
                <Grid item xs={12}>
            <span style={{ marginRight: '8px' }}>•</span><Link href={notiy.url} target='_blank'>{notiy.title}</Link>
                 
            </Grid>
            ))}
            
        </Grid> **/

        <div className="news-ticker-vertical">
          <div className="ticker-wrap-vertical">
            <div className="ticker-vertical" ref={tickerRef}>
              {notificationList.map((item, index) => (
                <a key={index} href={item.url} className="ticker-item-vertical" target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        </div>  
    )
}
export default Notifications;