import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Container, Typography } from '@mui/material'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import { TableDataPresentation, ListDataPresentation } from '../../../utils/utils'
import MainTitle from '../../muiHome/mainTitle'
import NewMain from '../../headerone/newMain'
function ScholarshipNew() {
	const SOETfeeStructureData = [
		{'Courses': 'B.Tech CSE', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '107000', '1st Year Fees': '124000', '2nd Year Onwards': '124000'},
		{'Courses': 'B.Tech CSE (AI & ML)', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '128000', '1st Year Fees': '145000', '2nd Year Onwards': '145000'},
		{'Courses': 'B.Tech CSE (Cyber Security)', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '107000', '1st Year Fees': '124000', '2nd Year Onwards': '124000'},
		{'Courses': 'B.Tech Civil Engineering', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '70000', '1st Year Fees': '87000', '2nd Year Onwards': '87000'},
		{'Courses': 'B.Tech ME (Robotics & Automation)', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '70000', '1st Year Fees': '87000', '2nd Year Onwards': '87000'},
		{'Courses': 'M.Tech CSE', 'Eligiblity': 'B.Tech CSE with 50% marks', 'Duration': '2', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
		{'Courses': 'B.Sc Computer Science', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '3', 'Tution Fee': '52000', '1st Year Fees': '69000', '2nd Year Onwards': '69000'},
		{'Courses': 'BCA', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '57000', '1st Year Fees': '74000', '2nd Year Onwards': '74000'},
		{'Courses': 'BCA (Data Science)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '65000', '1st Year Fees': '82000', '2nd Year Onwards': '82000'},
		{'Courses': 'BCA (AI & ML)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '65000', '1st Year Fees': '82000', '2nd Year Onwards': '82000'},
		{'Courses': 'MCA', 'Eligiblity': 'Graduate in Related Stream with 50% marks', 'Duration': '2', 'Tution Fee': '45000', '1st Year Fees': '62000', '2nd Year Onwards': '62000'}
	  ];
	  
	  const SBMCfeeStructureData = [
		{'Courses': 'B.Com (H)', 'Eligiblity': '10+2 with 50% marks in Commerce', 'Duration': '3', 'Tution Fee': '35000', '1st Year Fees': '52000', '2nd Year Onwards': '52000'},
		{'Courses': 'BBA', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '50000', '1st Year Fees': '67000', '2nd Year Onwards': '67000'},
		{'Courses': 'BBA (Digital Marketing)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
		{'Courses': 'BBA (Business Analytics)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
		{'Courses': 'MBA (HR, Marketing, Finance, Business Analytics)', 'Eligiblity': 'Any Graduate with 50%', 'Duration': '2', 'Tution Fee': '75000', '1st Year Fees': '92000', '2nd Year Onwards': '92000'},
		{'Courses': 'MBA (Pharm Management)', 'Eligiblity': 'Any Graduate with 50%', 'Duration': '2', 'Tution Fee': '75000', '1st Year Fees': '92000', '2nd Year Onwards': '92000'},
		{'Courses': 'MBA (Hospital Management)', 'Eligiblity': 'Any Graduate with 50%', 'Duration': '2', 'Tution Fee': '75000', '1st Year Fees': '92000', '2nd Year Onwards': '92000'}
	  ];

	  
	  const SOPSfeeStructureData=[
        {'Courses':'D. Pharmacy','Eligiblity':'10+2 with 50% marks in PCB/M','Duration':'2','Tution Fee':'70000' ,'1st Year Fees':'87000','2nd Year Onwards':'87000'},
        {'Courses':'B. Pharmacy','Eligiblity':'10+2 with 50% marks in PCB/M (Age 17 Years)','Duration':'4','Tution Fee':'130000' ,'1st Year Fees':'147000','2nd Year Onwards':'147000'},
        {'Courses':'Pharm D','Eligiblity':'10+2 with 50% marks in PCB/M','Duration':'6','Tution Fee':'175000' ,'1st Year Fees':'192000','2nd Year Onwards':'192000'},
        {'Courses':'M. Pharmacy (Pharmaceutics)','Eligiblity':'B. Pharmacy with 55% marks','Duration':'2','Tution Fee':'107000' ,'1st Year Fees':'124000','2nd Year Onwards':'124000'},
        {'Courses':'M. Pharmacy (Pharmacology)','Eligiblity':'B. Pharmacy with 55% marks','Duration':'2','Tution Fee':'107000' ,'1st Year Fees':'124000','2nd Year Onwards':'124000'},
        {'Courses':'M. Pharmacy (Drug Regulatory Affairs)','Eligiblity':'B. Pharmacy with 55% marks','Duration':'2','Tution Fee':'107000' ,'1st Year Fees':'124000','2nd Year Onwards':'124000'},
    ]



	const SOLfeeStructureData = [
		{ 'Courses': 'BA LLB', 'Eligiblity': '10+2 with 45% marks in Any Stream', 'Duration': '5', 'Tution Fee': '65000', '1st Year Fees': '82000', '2nd Year Onwards': '82000' },
		{ 'Courses': 'BBA LLB', 'Eligiblity': '10+2 with 45% marks in Any Stream', 'Duration': '5', 'Tution Fee': '62000', '1st Year Fees': '79000', '2nd Year Onwards': '79000' },
		{ 'Courses': 'LLB', 'Eligiblity': 'Any Graduate with 45% marks', 'Duration': '3', 'Tution Fee': '40000', '1st Year Fees': '57000', '2nd Year Onwards': '57000' },
		{ 'Courses': 'LLM', 'Eligiblity': 'BBA LLB, BA LLB, or LLB with 50% marks', 'Duration': '1', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000' }
	  ];
	  


const SAHSfeeStructureData = [
	{'Courses': 'Diploma in Health Assistant-DHA (After 10th Class)', 'Eligiblity': '10th/10+2 with 50% marks', 'Duration': '3', 'Tution Fee': '52000', '1st Year Fees': '69000', '2nd Year Onwards': '69000'},
	{'Courses': 'Diploma in Medical Lab Technology (DMLT)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '2', 'Tution Fee': '40000', '1st Year Fees': '57000', '2nd Year Onwards': '57000'},
	{'Courses': 'B.Sc in Medical Lab Technology (BMLT)', 'Eligiblity': '10+2 with 50% marks in PCB/M', 'Duration': '3', 'Tution Fee': '62000', '1st Year Fees': '79000', '2nd Year Onwards': '79000'},
	{'Courses': 'B.Sc (Biotechnology)', 'Eligiblity': '10+2 with 50% marks in PCB/M', 'Duration': '3', 'Tution Fee': '47000', '1st Year Fees': '64000', '2nd Year Onwards': '64000'},
	{'Courses': 'B.Sc CVT', 'Eligiblity': '10+2 with 50% marks in PCB', 'Duration': '4', 'Tution Fee': '72000', '1st Year Fees': '89000', '2nd Year Onwards': '89000'},
	{'Courses': 'Bachelor of Physiotherapy (BPT)', 'Eligiblity': '10+2 with 50% marks in PCB (Age- 17 Years)', 'Duration': '4.5', 'Tution Fee': '110000', '1st Year Fees': '127000', '2nd Year Onwards': '127000'},
	{'Courses': 'M.Sc Medical Lab Technology (M.Sc MLT)', 'Eligiblity': 'Graduate in Related Stream with 50% marks', 'Duration': '2', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
	{'Courses': 'M.Sc (Microbiology)', 'Eligiblity': 'Graduate in Related Stream with 50% marks', 'Duration': '2', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
  ];

  
  const SSHfeeStructureData = [
	{'Courses': 'B.Sc (Medical)', 'Eligiblity': '10+2 with 50% marks in PCB', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'B.Sc (Non Medical)', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'BA (Pass)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'BA (Economics-Honours)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'BA (Political Science)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'BA (Journalism & Mass Communication)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '50000', '1st Year Fees': '55000', '2nd Year Onwards': '50000'},
  ];

  

	const LEAfeeStructureData=[
		/*
        {'Courses':'B.Tech CSE (Leet)','Eligiblity':'Diploma/B.Sc (Maths) with 50% marks','Duration':'3','Tution Fee':'105000' ,'1st Year Fees':'130200','2nd Year Onwards':'114200'},
        {'Courses':'B.Pharmacy (Leet)','Eligiblity':'D.Pharm with 50% marks','Duration':'3','Tution Fee':'125000' ,'1st Year Fees':'150200','2nd Year Onwards':'134200'},
        {'Courses':'B.Sc inMedical Lab Technology (BMLT) (Leet)','Eligiblity':'DMLT with 50% marks','Duration':'2','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
	*/]

	const DoctoralfeeStructureData = [
		{'Courses': 'Ph.D (All)', 'Eligiblity': 'PG with 55% marks', 'Duration': '3', 'Tution Fee': '85000', '1st Year Fees': '102000', '2nd Year Onwards': '102000'},
	  ];
	  
	  const hostalFeeData = [
		{ item: 'Triple Seater (Non AC) with Meal', fee: '₹ 96000/-' },
		{ item: 'Triple Seater (AC) with Meal', fee: '₹ 130000/-' }
	  ];

	  const noteList = [
		{ listName: '1. HSTES Counselling fee (B.Pharm) will be charged extra.' },
		{ listName: '2. For Pharmacy courses eligibility documents verification is mandatory as per PCI norms and charges for the same will be charged extra.' },
		{ listName: '3. UGC norms will be adhered to for fee refunds.' },
		{ listName: '4. Hostel/Transport Fee will be applicable for the full academic year only.' },
		{ listName: '5. All disputes are subject to Palwal judiciary.' }
	  ];

	  
	return (
		<>
			<NewMain />
			<Bredcom title="Home" subtitle="Fee Structure With Eligibility 2025-26" />
			<Container>


			 
			<h3 class="newtitle">B.Sc. Cardiovascular Technology (BCVT)</h3>


			<div className='containe_tedxt'>
				<h4>About the Program</h4>
				<p>About the Program	A Cardiovascular Technologist is a healthcare professional who specializes in diagnosing and treating heart and blood vessel conditions. They work with physicians to conduct tests and procedures such as echocardiograms, stress tests, electrocardiograms (ECG/EKG), and cardiac catheterization. Cardiovascular Technologists play a critical role in assisting in life-saving procedures and improving patient care.

Bachelor of Cardiovascular Technology (BCVT) students gain expertise in operating sophisticated diagnostic equipment and interpreting cardiovascular imaging and data. Their role is essential in hospitals, diagnostic centers, and research institutions to ensure accurate cardiovascular assessments.
</p>
			</div>

			<div className='containe_tedxt'>
				<h4>Eligibility</h4>
				<h6>Minimum Eligibility Criteria</h6>
				<p>Pass in 10+2 (PCB) examination. <br/>
Minimum 55% aggregate marks for general category and 45% aggregate marks for SC/ST category.
</p>
				<br/>
				<h6>Criteria for Selection</h6>
				<p>The selection and ranking of candidates will be based on their 12th-grade final examination scores. Aggregate marks in PCB/PCM will only be considered for the merit list.</p>

			</div>

			<div className='containe_tedxt'>
				<h4>Programme Highlights</h4>
				<ul>
					<li>Minimum Eligibility Criteria</li>
					<li>Pass in 10+2 (PCB) examination.</li>
					<li>Minimum 55% aggregate marks for general category and 45% aggregate marks for SC/ST category.</li>
					<li>Criteria for Selection</li>
					<li>The selection and ranking of candidates will be based on their 12th-grade final examination scores. Aggregate marks in PCB/PCM will only be considered for the merit list.</li>
				</ul>
				<h6>Why one should pursue BCVT</h6>
				<p>The healthcare industry has a growing demand for cardiovascular technologists. Career opportunities in hospitals, clinics, diagnostic centres, and research institutions. The program offers comprehensive training in both invasive and non-invasive cardiovascular procedures. 
Opportunity to work alongside cardiologists and healthcare professionals. The potential to pursue higher studies in specialized cardiovascular fields.
</p>
			</div>

			<div className='containe_tedxt'>
				<h4> Fee Structure</h4>
				<p>The fee structure aims to provide clarity, empowering you and your family to plan effectively for the entire three-year program. Here, you'll discover a thorough breakdown of tuition fees, outlining costs for both the first year and subsequent years.

</p>

<table>
	<tr>
		<th>Duration</th>
		<th>Tuition Fee</th>
		<th>1st Year Fees</th>
		<th>2nd Year Onwards</th> 
	</tr>

	<tr>
		<td> 3 Years	</td> 
		<td> 72,000	</td> 
		<td> 89,000 </td> 
		<td> 	72,000  </td> 
	</tr>


</table>

<p>Enhance your study experience at our institution by choosing from our array of hostel accommodations designed for your comfort and convenience. We provide diverse options to cater to your preferences, from triple-seater rooms, available in both air-conditioned and non-air-conditioned variants, to inclusive meal plans. Below, you'll discover comprehensive details on our hostel fee structures tailored to each accommodation option.</p>


<table>
	<tr>
		<th colSpan={2}>HOSTEL FEE</th> 
	</tr>

	<tr>
		<td> Triple seater with meal</td> 
		<td> 86000</td>  
	</tr>

	<tr>
		<td> Triple seater (AC) with meal</td> 
		<td> 110000</td>  
	</tr>

	<tr>
		<td colSpan={2}> Note: Hostel caution money of Rs. 10000/- one time payment (Refundable)</td>  
	</tr>


</table>


			</div>

			<div className='containe_tedxt scholerpship_list'>
				<h4> Scholarship</h4>
				 
				 <h5 className='table_had'> GOOD IN STUDIES</h5>
				<table>
					<thead>
					<tr>
						<th>S. No.</th>
						<th>Qualifying Examination Marks/ CGPA</th>
						<th>Scholarship (2024-25)</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>1</td>
						<td>90% and above</td>
						<td>50% Tuition fee waiver</td>
					</tr>
					<tr>
						<td>2</td>
						<td>85% and above but below 90%</td>
						<td>35% Tuition fee waiver</td>
					</tr>
					<tr>
						<td>3</td>
						<td>80% and above but below 85%</td>
						<td>25% Tuition fee waiver</td>
					</tr>
					<tr>
						<td>4</td>
						<td>75% and above but below 80%</td>
						<td>15% Tuition fee waiver</td>
					</tr>
					<tr>
						<td>5</td>
						<td>65% and above but below 75%</td>
						<td>10% Tuition fee waiver</td>
					</tr>
					</tbody>
				</table>
			
			<h5 className='table_had'> SCHOLARSHIP VIA CUET-UG/PG 2024</h5>

			<table>
				<thead>
				<tr>
					<th>S. No.</th>
					<th>CUET Marks/Score</th>
					<th>Scholarship (2024-25)</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>1</td>
					<td>95% and above</td>
					<td>100% Tuition Fee waiver</td>
				</tr>
				<tr>
					<td>2</td>
					<td>85% and above but below 95%</td>
					<td>50% Tuition Fee waiver</td>
				</tr>
				<tr>
					<td>3</td>
					<td>75% and above but below 85%</td>
					<td>25% Tuition Fee waiver</td>
				</tr>
				<tr>
					<td>4</td>
					<td>50% and above but below 75%</td>
					<td>15% Tuition Fee waiver</td>
				</tr>
				</tbody>
			</table>

			<h5 className='table_had'> GOOD IN SPORTS</h5>
			<table>
				<thead>
				<tr>
					<th>S. No.</th>
					<th>Event/Level</th>
					<th>Participation</th>
					<th>Scholarship (2024-25)</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>1</td>
					<td>Olympics</td>
					<td>100% Tuition Fee waiver</td>
					<td>100% Tuition Fee waiver</td>
				</tr>
				<tr>
					<td>2</td>
					<td>Asian Games / Asian Championship</td>
					<td>75% Tuition Fee waiver</td>
					<td>100% Tuition Fee waiver</td>
				</tr>
				<tr>
					<td>3</td>
					<td>Common Wealth Games</td>
					<td>50% Tuition Fee waiver</td>
					<td>75% Tuition Fee waiver</td>
				</tr>
				<tr>
					<td>4</td>
					<td>World Cup</td>
					<td>50% Tuition Fee waiver</td>
					<td>75% Tuition Fee waiver</td>
				</tr>
				<tr>
					<td>5</td>
					<td>National</td>
					<td>50% Tuition Fee waiver</td>
					<td>75% Tuition Fee waiver</td>
				</tr>
				<tr>
					<td>6</td>
					<td>State (with compulsory participation in National)</td>
					<td>25% Tuition Fee waiver</td>
					<td>50% Tuition Fee waiver</td>
				</tr>
				<tr>
					<td>7</td>
					<td>School/College/District</td>
					<td>10% Tuition Fee waiver</td>
					<td>-</td>
				</tr>
				</tbody>
			</table>

			<h5 className='table_had'> HAVING FINANCIAL ISSSUES ?</h5>
			<table>
				<thead>
				<tr>
					<th>S. No.</th>
					<th>Category/Particulars</th>
					<th>Scholarship (2024-25)</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>1</td>
					<td>Sh. Gopal Sharma Scholarship and Mentorship scheme for Poor / Underprivileged Girls child (for Palwal, Mewat, Faridabad, Gurugram, Aligarh & Mathura District) (poor girl child)</td>
					<td>Upto 50% Tuition fee waiver (Scholarships will be decided by the university admissions committee after verification of Income & Domicile documents and physical verification only ). Family income is less than or equal to 5 Lakhs.</td>
				</tr>
				<tr>
					<td>2</td>
					<td>Sh. Gopal Sharma Scholarship and Mentorship scheme for Orphans & Single-Parent Child</td>
					<td>Upto 50% Tuition fee waiver (Scholarships will be decided by the university admissions committee after verification of all relevant documents and physical verification.)</td>
				</tr>
				</tbody>
			</table>

			<h5 className='table_had'> GIRLS STUDENTS OF KHAMBI VILLEGE (A HOLY LAND)</h5>
			<table>
				<thead>
				<tr>
					<th>S. No.</th>
					<th>Qualifying Examination Marks/CGPA</th>
					<th>Scholarship (2024-2025)</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>1</td>
					<td>50% and above</td>
					<td>80% Tuition fee waiver</td>
				</tr>
				</tbody>
			</table>

			<h5 className='table_had'> SH. GOPAL SHARMA MERITORIOUS AWARDS (ADDITIONAL AWARDS OF SCHOLARSHIPS)</h5>

			
			<table>
    <thead>
      <tr>
        <th>Position</th>
        <th>Prize Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1st Position Holder in Program</td>
        <td>10,000/- Rs. Prize</td>
      </tr>
      <tr>
        <td>2nd Position Holder in Program</td>
        <td>5,000/- Rs. Prize</td>
      </tr>
      <tr>
        <td>3rd Position Holder in Program</td>
        <td>2,500/- Rs. Prize</td>
      </tr>
    </tbody>
  </table>


  <h5 className='table_had'> SWAMI DAYANAND EDUCATION FOUNDATION: TO NURTURE POOR TALANTED STUDENTS </h5>

  <table>
    <thead>
      <tr>
        <th>S. No.</th>
        <th>Category</th>
        <th>Scholarship Details</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>Any Program/Course</td>
        <td>25,000/- Rs scholarship for 1st 10 Students</td>
      </tr>
    </tbody>
  </table>


  
  <h5 className='table_had'> CONTINUED EXCELLENCE </h5>
  <table>
    
    <tbody>
      <tr>
        <td>Eligibility Conditions for 2nd year onwards scholarships</td>
        <td>Attendance should not be less than 85%</td>
        <td>Semester(s) examination result not less than 8.0 CGPA</td>
		<td>Student must not involve in any indiscipline activity. </td>
      </tr>
    </tbody>
  </table>



			</div>


			<div className='containe_tedxt'>
				<h4> Career Path</h4>
				 <p> BCVT graduates have diverse career opportunities in:</p>

				 <ul >
					<li>Hospitals & Healthcare Facilities: Assisting in the diagnosis and treatment of cardiovascular diseases.</li>
					<li>Diagnostic Centers: Performing and interpreting cardiovascular diagnostic tests.</li>
					<li>Research Institutions: Engaging in cardiovascular research and innovation.</li>
					<li>Public Health Organizations: Contributing to cardiovascular disease prevention and management.</li>
					<li>Medical Equipment Companies: Working in sales, training, and technical support for cardiovascular diagnostic tools.</li>
				</ul>

				
			</div>

			<div className='containe_tedxt'>
				<h4>Programme Outcomes (PO’s)  </h4>
				<ul  >
				<li><strong> PO1: </strong> Demonstrate knowledge and technical expertise in cardiovascular diagnostic procedures.</li>
				<li><strong>PO2: </strong>  Perform safe and efficient use of cardiovascular imaging equipment such as echocardiograms, ECGs, and Holter monitoring.</li>
				<li><strong>PO3: </strong> Conduct high-quality diagnostic procedures within established medical guidelines.</li>
				<li><strong>PO4: </strong> Identify and troubleshoot issues in cardiovascular testing and equipment operation.</li>
				<li><strong>PO5:</strong>  Exhibit professionalism, ethical standards, and teamwork in clinical settings.</li>
				<li><strong>PO6: </strong> Understand the impact of cardiovascular technology in a global and environmental context.</li>
				<li><strong>PO7: </strong> Apply research skills to contribute to advancements in cardiovascular technology.</li>
				<li><strong>PO8:</strong>  Gain practical experience through internships in multi-specialty hospitals.</li>
			</ul>
			</div>

			<div className='containe_tedxt'>
				<h4> Program Specific Outcomes (PSO’s) </h4>
				<ul >
					<li><strong>PSO1: </strong> Demonstrate proficiency in performing non-invasive cardiovascular diagnostic procedures, including ECG, Holter monitoring, and stress tests.</li>
					<li><strong>PSO2: </strong> Assist in invasive cardiovascular procedures such as cardiac catheterization and angiography under the supervision of cardiologists.</li>
					<li><strong>PSO3:</strong>  Analyze and interpret cardiovascular diagnostic data to assist in accurate diagnosis and treatment planning.</li>
					<li><strong>PSO4:</strong>  Operate and maintain advanced cardiovascular diagnostic equipment, ensuring accuracy and efficiency.</li>
					<li><strong>PSO5:</strong>  Apply principles of cardiovascular physiology and pathology in clinical decision-making.</li>
					<li><strong>PSO6:</strong>  Educate patients on cardiovascular health, risk factors, and preventive measures.</li>
					<li><strong>PSO7:</strong>  Demonstrate competency in emergency response techniques related to cardiac events and procedures.</li>
					<li><strong>PSO8:</strong>  Engage in continuous learning and professional development to keep up with advancements in cardiovascular technology.</li>
				</ul>
			</div>

			<div className='containe_tedxt'>
				<h4>USP’s </h4>
				<ul  >
					<li>Cutting-edge curriculum aligned with global healthcare trends.</li>
					<li>Hands-on experience with the latest cardiovascular diagnostic tools.</li>
					<li>Industry collaborations for skill-enhancement and job placement.</li>
					<li>Opportunities for international research exposure.</li>
					<li>Strong emphasis on practical training and clinical internships.</li>
				</ul>
			</div>

			<div className='containe_tedxt'>
				<h4> Benefits</h4>
				<ul>
					<li>1. High demand for trained cardiovascular technologists in hospitals and diagnostic centers.</li>
					<li>2. Opportunity to work in a fast-growing healthcare sector with job stability.</li>
					<li>3. Hands-on experience with cutting-edge cardiovascular diagnostic technology.</li>
					<li>4. Contribution to life-saving procedures and improving patient health outcomes.</li>
					<li>5. Diverse career opportunities in hospitals, clinics, research institutions, and medical device companies.</li>
					<li>6. Competitive salary packages with potential for growth and specialization.</li>
					<li>7. Strong emphasis on research and innovation in cardiovascular sciences.</li>
					<li>8. International career prospects due to the global demand for cardiovascular technologists.</li>
					<li>9. Pathway to higher studies in specialized cardiovascular fields, such as echocardiography and interventional cardiology.</li>
					<li>10. Exposure to real-world clinical environments through internships and industry collaborations.</li>
				</ul>
			</div>

			<div className='containe_tedxt'>
				<h4> FAQs</h4>
				<dl>
					<dt>1. Is BCVT a good career option?</dt>
					<dd>Yes, BCVT graduates are in high demand across hospitals, research centers, and diagnostic labs due to the rising prevalence of cardiovascular diseases.</dd>

					<dt>2. What is the salary of a Cardiovascular Technologist in India?</dt>
					<dd>The starting salary ranges from ₹4-6 LPA, increasing with experience and specialization.</dd>

					<dt>3. What are the job opportunities after BCVT?</dt>
					<dd>Graduates can work as Cardiovascular Technologists, Cardiac Sonographers, EKG Technicians, or pursue research in cardiovascular sciences.</dd>

					<dt>4. What is the duration of the BCVT program?</dt>
					<dd>The BCVT program is a 3-year undergraduate course with clinical training.</dd>

					<dt>5. Can BCVT graduates pursue higher studies?</dt>
					<dd>Yes, they can pursue M.Sc. in Cardiovascular Technology, M.Sc. in Echocardiography, or other specialized cardiovascular programs.</dd>
				</dl>
			</div>




			  
			</Container>
			

		</>
	)
}

export default ScholarshipNew