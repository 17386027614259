import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Container, Typography } from '@mui/material'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import { TableDataPresentation, ListDataPresentation } from '../../../utils/utils'
import MainTitle from '../../muiHome/mainTitle'
import NewMain from '../../headerone/newMain'
function FeeStructure() {
	const SOETfeeStructureData = [
		{'Courses': 'B.Tech CSE', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '107000', '1st Year Fees': '124000', '2nd Year Onwards': '124000'},
		{'Courses': 'B.Tech CSE (AI & ML)', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '128000', '1st Year Fees': '145000', '2nd Year Onwards': '145000'},
		{'Courses': 'B.Tech CSE (Cyber Security)', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '107000', '1st Year Fees': '124000', '2nd Year Onwards': '124000'},
		{'Courses': 'B.Tech Civil Engineering', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '70000', '1st Year Fees': '87000', '2nd Year Onwards': '87000'},
		{'Courses': 'B.Tech ME (Robotics & Automation)', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '4', 'Tution Fee': '70000', '1st Year Fees': '87000', '2nd Year Onwards': '87000'},
		{'Courses': 'M.Tech CSE', 'Eligiblity': 'B.Tech CSE with 50% marks', 'Duration': '2', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
		{'Courses': 'B.Sc Computer Science', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '3', 'Tution Fee': '52000', '1st Year Fees': '69000', '2nd Year Onwards': '69000'},
		{'Courses': 'BCA', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '57000', '1st Year Fees': '74000', '2nd Year Onwards': '74000'},
		{'Courses': 'BCA (Data Science)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '65000', '1st Year Fees': '82000', '2nd Year Onwards': '82000'},
		{'Courses': 'BCA (AI & ML)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '65000', '1st Year Fees': '82000', '2nd Year Onwards': '82000'},
		{'Courses': 'MCA', 'Eligiblity': 'Graduate in Related Stream with 50% marks', 'Duration': '2', 'Tution Fee': '45000', '1st Year Fees': '62000', '2nd Year Onwards': '62000'}
	  ];
	  
	  const SBMCfeeStructureData = [
		{'Courses': 'B.Com (H)', 'Eligiblity': '10+2 with 50% marks in Commerce', 'Duration': '3', 'Tution Fee': '35000', '1st Year Fees': '52000', '2nd Year Onwards': '52000'},
		{'Courses': 'BBA', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '50000', '1st Year Fees': '67000', '2nd Year Onwards': '67000'},
		{'Courses': 'BBA (Digital Marketing)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
		{'Courses': 'BBA (Business Analytics)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
		{'Courses': 'MBA (HR, Marketing, Finance, Business Analytics)', 'Eligiblity': 'Any Graduate with 50%', 'Duration': '2', 'Tution Fee': '75000', '1st Year Fees': '92000', '2nd Year Onwards': '92000'},
		{'Courses': 'MBA (Pharm Management)', 'Eligiblity': 'Any Graduate with 50%', 'Duration': '2', 'Tution Fee': '75000', '1st Year Fees': '92000', '2nd Year Onwards': '92000'},
		{'Courses': 'MBA (Hospital Management)', 'Eligiblity': 'Any Graduate with 50%', 'Duration': '2', 'Tution Fee': '75000', '1st Year Fees': '92000', '2nd Year Onwards': '92000'}
	  ];

	  
	  const SOPSfeeStructureData=[
        {'Courses':'D. Pharmacy','Eligiblity':'10+2 with 50% marks in PCB/M','Duration':'2','Tution Fee':'70000' ,'1st Year Fees':'87000','2nd Year Onwards':'87000'},
        {'Courses':'B. Pharmacy','Eligiblity':'10+2 with 50% marks in PCB/M (Age 17 Years)','Duration':'4','Tution Fee':'130000' ,'1st Year Fees':'147000','2nd Year Onwards':'147000'},
        {'Courses':'Pharm D','Eligiblity':'10+2 with 50% marks in PCB/M','Duration':'6','Tution Fee':'175000' ,'1st Year Fees':'192000','2nd Year Onwards':'192000'},
        {'Courses':'M. Pharmacy (Pharmaceutics)','Eligiblity':'B. Pharmacy with 55% marks','Duration':'2','Tution Fee':'107000' ,'1st Year Fees':'124000','2nd Year Onwards':'124000'},
        {'Courses':'M. Pharmacy (Pharmacology)','Eligiblity':'B. Pharmacy with 55% marks','Duration':'2','Tution Fee':'107000' ,'1st Year Fees':'124000','2nd Year Onwards':'124000'},
        {'Courses':'M. Pharmacy (Drug Regulatory Affairs)','Eligiblity':'B. Pharmacy with 55% marks','Duration':'2','Tution Fee':'107000' ,'1st Year Fees':'124000','2nd Year Onwards':'124000'},
    ]



	const SOLfeeStructureData = [
		{ 'Courses': 'BA LLB', 'Eligiblity': '10+2 with 45% marks in Any Stream', 'Duration': '5', 'Tution Fee': '65000', '1st Year Fees': '82000', '2nd Year Onwards': '82000' },
		{ 'Courses': 'BBA LLB', 'Eligiblity': '10+2 with 45% marks in Any Stream', 'Duration': '5', 'Tution Fee': '62000', '1st Year Fees': '79000', '2nd Year Onwards': '79000' },
		{ 'Courses': 'LLB', 'Eligiblity': 'Any Graduate with 45% marks', 'Duration': '3', 'Tution Fee': '40000', '1st Year Fees': '57000', '2nd Year Onwards': '57000' },
		{ 'Courses': 'LLM', 'Eligiblity': 'BBA LLB, BA LLB, or LLB with 50% marks', 'Duration': '1', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000' }
	  ];
	  


const SAHSfeeStructureData = [
	{'Courses': 'Diploma in Health Assistant-DHA (After 10th Class)', 'Eligiblity': '10th/10+2 with 50% marks', 'Duration': '3', 'Tution Fee': '52000', '1st Year Fees': '69000', '2nd Year Onwards': '69000'},
	{'Courses': 'Diploma in Medical Lab Technology (DMLT)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '2', 'Tution Fee': '40000', '1st Year Fees': '57000', '2nd Year Onwards': '57000'},
	{'Courses': 'B.Sc in Medical Lab Technology (BMLT)', 'Eligiblity': '10+2 with 50% marks in PCB/M', 'Duration': '3', 'Tution Fee': '62000', '1st Year Fees': '79000', '2nd Year Onwards': '79000'},
	{'Courses': 'B.Sc (Biotechnology)', 'Eligiblity': '10+2 with 50% marks in PCB/M', 'Duration': '3', 'Tution Fee': '47000', '1st Year Fees': '64000', '2nd Year Onwards': '64000'},
	{'Courses': 'B.Sc CVT', 'Eligiblity': '10+2 with 50% marks in PCB', 'Duration': '4', 'Tution Fee': '72000', '1st Year Fees': '89000', '2nd Year Onwards': '89000'},
	{'Courses': 'Bachelor of Physiotherapy (BPT)', 'Eligiblity': '10+2 with 50% marks in PCB (Age- 17 Years)', 'Duration': '4.5', 'Tution Fee': '110000', '1st Year Fees': '127000', '2nd Year Onwards': '127000'},
	{'Courses': 'M.Sc Medical Lab Technology (M.Sc MLT)', 'Eligiblity': 'Graduate in Related Stream with 50% marks', 'Duration': '2', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
	{'Courses': 'M.Sc (Microbiology)', 'Eligiblity': 'Graduate in Related Stream with 50% marks', 'Duration': '2', 'Tution Fee': '60000', '1st Year Fees': '77000', '2nd Year Onwards': '77000'},
  ];

  
  const SSHfeeStructureData = [
	{'Courses': 'B.Sc (Medical)', 'Eligiblity': '10+2 with 50% marks in PCB', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'B.Sc (Non Medical)', 'Eligiblity': '10+2 with 50% marks in PCM', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'BA (Pass)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'BA (Economics-Honours)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'BA (Political Science)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '30000', '1st Year Fees': '35000', '2nd Year Onwards': '30000'},
	{'Courses': 'BA (Journalism & Mass Communication)', 'Eligiblity': '10+2 with 50% marks in Any Stream', 'Duration': '3', 'Tution Fee': '50000', '1st Year Fees': '55000', '2nd Year Onwards': '50000'},
  ];

  

	const LEAfeeStructureData=[
		/*
        {'Courses':'B.Tech CSE (Leet)','Eligiblity':'Diploma/B.Sc (Maths) with 50% marks','Duration':'3','Tution Fee':'105000' ,'1st Year Fees':'130200','2nd Year Onwards':'114200'},
        {'Courses':'B.Pharmacy (Leet)','Eligiblity':'D.Pharm with 50% marks','Duration':'3','Tution Fee':'125000' ,'1st Year Fees':'150200','2nd Year Onwards':'134200'},
        {'Courses':'B.Sc inMedical Lab Technology (BMLT) (Leet)','Eligiblity':'DMLT with 50% marks','Duration':'2','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
	*/]

	const DoctoralfeeStructureData = [
		{'Courses': 'Ph.D (All)', 'Eligiblity': 'PG with 55% marks', 'Duration': '3', 'Tution Fee': '85000', '1st Year Fees': '102000', '2nd Year Onwards': '102000'},
	  ];
	  
	  const hostalFeeData = [
		{ item: 'Triple Seater (Non AC) with Meal', fee: '₹ 96000/-' },
		{ item: 'Triple Seater (AC) with Meal', fee: '₹ 130000/-' }
	  ];

	  const noteList = [
		{ listName: '1. HSTES Counselling fee (B.Pharm) will be charged extra.' },
		{ listName: '2. For Pharmacy courses eligibility documents verification is mandatory as per PCI norms and charges for the same will be charged extra.' },
		{ listName: '3. UGC norms will be adhered to for fee refunds.' },
		{ listName: '4. Hostel/Transport Fee will be applicable for the full academic year only.' },
		{ listName: '5. All disputes are subject to Palwal judiciary.' }
	  ];

	  
	return (
		<>
			<NewMain />
			<Bredcom title="Home" subtitle="Fee Structure With Eligibility 2025-26" />
			<Container>
			<Grid container spacing={2} marginTop={10}>
				<Grid item xs={12}>
					<MainTitle title='Fee Structure With Eligibility 2025-26'/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of engineering & Technology'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SOETfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of business management and commerce'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SBMCfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of Pharmaceutical science'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SOPSfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of Law'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SOLfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of Allied health sciences'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SAHSfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of Science and humanities'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SSHfeeStructureData}/>
				</Grid>
				{/*<Grid item xs={12}>
					<MainTitle title='Lateral Entery Admissions Fee Structure'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={LEAfeeStructureData}/>
				</Grid>  */}
				<Grid item xs={12}>
					<MainTitle title='Doctoral Programmes'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={DoctoralfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='Hostel Fees'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={hostalFeeData}/>
				</Grid>
				<Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
	<Grid item xs={12}>
    <MainTitle title="Notes" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={noteList}/>
    </Grid>
			</Grid>
			</Container>
			

		</>
	)
}

export default FeeStructure