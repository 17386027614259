import SOETHeader from "./header";
import BreadcrumbsBanner from "./breadcrumbs";
import SOETContent from "./home";
import { Typography, Grid, Card, Avatar, Box } from '@mui/material';
import { Container } from "@mui/material";
import MainTitle from '../muiHome/mainTitle';
import SOETDepartments from "./departments";
import EventNewsGallary from "./eventNews";
import RecuiterCompany from "./recuiterCompany";
// import WidgetsMenu from "./menuWidgets";
import NewMain from "../headerone/newMain";



import { ListDataPresentation, ReactSlider, TableDataPresentation } from '../../utils/utils';
import WidgetsMenu from "../muiCommonComponent/menuWidgets";
const SOETHome=()=>{

    const SAHSfeeStructureData=[
        {'Courses':'B.Tech CSE','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'107000','1st Year Fees':'124000','2nd Year Onwards':'124000'},
        {'Courses':'B.Tech CSE (AI & ML)','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'128000','1st Year Fees':'145000','2nd Year Onwards':'145000'},
        {'Courses':'B.Tech CSE (Cyber Security)','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'107000','1st Year Fees':'124000','2nd Year Onwards':'124000'},
        {'Courses':'B.Tech Civil Engineering','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'70000','1st Year Fees':'87000','2nd Year Onwards':'87000'},
        {'Courses':'B.Tech ME (Robotics & Automation)','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'70000','1st Year Fees':'87000','2nd Year Onwards':'87000'},
        {'Courses':'M.Tech CSE','Eligiblity':'B.Tech CSE with 50% marks','Duration':'2','Tution Fee':'60000','1st Year Fees':'77000','2nd Year Onwards':'77000'},
        {'Courses':'B.Sc Computer Science','Eligiblity':'10+2 with 50% marks in PCM','Duration':'3','Tution Fee':'52000','1st Year Fees':'69000','2nd Year Onwards':'69000'},
        {'Courses':'BCA','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'57000','1st Year Fees':'74000','2nd Year Onwards':'74000'},
        {'Courses':'BCA (Data Science)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'65000','1st Year Fees':'82000','2nd Year Onwards':'82000'},
        {'Courses':'BCA (AI & ML)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'65000','1st Year Fees':'82000','2nd Year Onwards':'82000'},
        {'Courses':'MCA','Eligiblity':'Graduate in Related Stream with 50% marks','Duration':'2','Tution Fee':'45000','1st Year Fees':'62000','2nd Year Onwards':'62000'}
        ];
        
        
        
        
  

    const headerMenus = [
        { mainHeader: 'Home', path: '/school-of-engineering-technology', subHeader: [] },
        { mainHeader: 'SOET Insights', path: '', subHeader: [
            { title: 'Insights', path: '/soet/insights', },
            { title: 'Vision & Mission', path: '/soet/vision-mission', },
            { title: 'Knowledge Partners', path: '/soet/knowledge-partners', },
            { title: 'Pedagogical Advances – MOOCs', path: '/soet/mcoos', },
        ] },
        { mainHeader: 'SOET Departments', path: '', subHeader: [
            { title: 'Computer Science & Engineering', path: '/soet-cse/home', },
            { title: 'Mechanical Engineering', path: '/soet-me/home' },
            { title: 'Civil Engineering', path: '/soet-civil/home' },
            { title: 'Electronic & Communication Engineering', path: '/soet-ece/home' },
        ] },
        { mainHeader: 'MVN Times', path: '#', subHeader: [] },
        { mainHeader: 'Scholarly Work ', path: '#', subHeader: [] },
      ];


return(<>
<NewMain/>
{/* <Navbar/> */}
<WidgetsMenu headerMenus={headerMenus}/>
<BreadcrumbsBanner bannerUrl={"/assets/img/SOET/soet.jpg"}/>
<Container>
<SOETContent/>


<SOETDepartments/>
<EventNewsGallary/>
<RecuiterCompany/>



<Grid item xs={12} className="fee-struct_t">
					<MainTitle title='FEE STRUCTURE – 2025-26'/>
                   
				</Grid> 
				<Grid item xs={12}>
				<TableDataPresentation data={SAHSfeeStructureData}/>
				</Grid>



</Container>
</>)
}
export default SOETHome;