import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
const BBADM=()=>{
    const progHiglight=[
{listName:'Gain hands-on experience through industry internships, on-the-job training, and major projects, preparing you for diverse career paths in the media and entertainment industry or pursuing doctoral research.'},
{listName:'Learn from top media professionals, who will provide you with valuable insights and practical knowledge, enhancing your skills in events, public relations, corporate communications, and social media marketing.'},
{listName:'The BBA (Events, Public Relations, and Corporate Communications) – Social Media & Marketing program focuses on emerging trends and strategies in social media and marketing equipping you with the latest tools and techniques to excel in the digital marketing space.'},
{listName:'Develop a strong foundation for versatile career paths, whether in media and entertainment industry roles or pursuing further academic excellence in doctoral research.'},
{listName:'Cultivate a well-rounded skill set, combining creativity, communication, and analytical abilities, to thrive in the dynamic world of events, public relations, and corporate communications.'},
{listName:'BBA in Digital Marketing students need skills like analyzing the market, sales conversion, creating market strategies, and more. '},
{listName:'Special student grooming sessions and industry drives for placement Develop a deep understanding of the way modern businesses run'},
{listName:'Ability to apply digital marketing tools to enhance reach of brands and businesses'},
{listName:'Understanding of Global Business Environment'},
{listName:'Practical exposure with help of Industrial visits and Internship opportunities.'},
{listName:'Imparting Entrepreneurial Skills'},
{listName:'Improving Effective Communication Skills and holistic personality development'},
{listName:'Increasing Ethical and Social Awareness'},
{listName:'Prepare graduates for entry level job opportunities'},

      
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹77000/- (Tuition Fee - ₹60000 + Other Charges- ₹17000/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹77000/- ( Tuition Fee – ₹60000 + Other Charges- ₹17000/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 96000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹130000/-'}
    ]
    const duration='3 years'
    const fee_1st= '77,000'
    const fee_2nd= '77,000'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="BBA (Digital Marketing)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    MVN university's BBA program is one of the most sought-after Bachelor’s degree programs for students after Class 12. The MVN university BBA (Marketing) program is the gateway of job opportunities in business domains such as social media manager, digital marketing specialist, PR executive, event coordinator, corporate communications officer, and marketing analyst. BBA in Digital Marketing offers students’ knowledge and understanding about the marketing world digitally. Further elaborating, BBA (Marketing) of MVN university is a three-year professional Undergraduate course in Business Management. It is open to students from all three streams i.e., Science, Arts and Commerce. The BBA (Events, Public Relations, and Corporate Communications) - Social Media and Marketing program offered by MVN aims to meet the growing demand for skilled professionals in the communications industry in India. With a focus on social media, marketing, and analytics, this program equips students with the necessary skills to excel in strategic communications, event management, public relations, and corporate communications. Through a carefully crafted curriculum, students gain expertise in marketing campaign development, both internal and external communication skills, and strategic media planning. The program is well-suited for those aspiring to build careers in PR agencies, event management companies, corporations, or the public sector.
    <br/><br/>
The department constantly endeavours to contribute the best towards academic excellence and is committed towards making student experience the finest. We strongly believe in endorsing and nourishing an atmosphere where ideas are nurtured and shared, students are enthused and teaching is dynamic. We genuinely care for our students, our assets and future glory and seek their valuable suggestions and inputs all along. The students we serve are our greatest priority and we’re committed to dedicating all our resources and channelizing all our energies in an effort to supporting their success.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    BBA (Digital Marketing)
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default BBADM;